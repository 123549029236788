const initialState = {
	messages: []
};

const mutations = {

	addMessage: (state, msg) => {
		state.messages.push(msg);
	},

	clearMessages: (state) => {
		state.messages = [];
	}
};

export default {
	namespaced: true,
	state: initialState,
	mutations
}

<template>

<div>

	<Modal
		v-show="isShowingExportManager"
		@close="showExportManager([false])"
		@clickOutside="showExportManager([false])"
		:additional-class="exportManagerModalContent === 'colorsStandalone' || exportManagerModalContent === 'colorsAllinone' ? 'modal--expand' : ''"
	>

		<div v-if="exportManagerModalContent === 'cart'">
			<header class="modal__header">{{title}}</header>
			<div class="modal__content__wrapper">
				<ExportManagerCart/>
			</div>
		</div>

		<div v-if="exportManagerModalContent === 'colorsStandalone' || exportManagerModalContent === 'colorsAllinone'">
			<header class="modal__header">{{generator.config.exportTitle}}</header>

			<slot name="colorTable"></slot>
			<footer class="modal__footer buttongroup">
				<button
					class="button icon icon--download"
					:aria-label="getL10nFromConfig('addToCart')"
					@click="setColorSelection(); addToCart();"
					v-if="exportManagerModalContent === 'colorsAllinone'"
				>
					{{getL10nFromConfig('addToCart')}}
				</button>

				<button
					class="button icon icon--download"
					:aria-label="getL10nFromConfig('getFiles')"
					@click="setColorSelection(); showExportManager([true, 'downloadStandalone']);"
				>
					{{getL10nFromConfig('getFiles')}}
				</button>

			</footer>
		</div>


		<div v-if="exportManagerModalContent === 'downloadStandalone' || exportManagerModalContent === 'downloadAllinone'">
			<header class="modal__header">{{title}}</header>
			<div class="modal__content__wrapper">

				<section class="modal__content modal__content--text">
					<ul class="modal__list modal__list--download" v-if="exportManagerModalContent === 'downloadStandalone'">
						<li v-for="item in generator.config.exports" :key="item">
							<h3 class="modal__list__title" v-html="getL10nFromConfig(`exportManager${item}Title`)"></h3>
							<p v-html="getL10nFromConfig(`exportManager${item}Description`)"></p>
						</li>
					</ul>

					<h3>{{getL10nFromConfig('downloadAgreementTitle')}}</h3>
					<p v-html="getL10nFromConfig('downloadAgreementText')"></p>
					<!--<p>{{getL10nFromConfig('downloadValidityNotice')}}</p>-->
				</section>

				<footer class="modal__footer buttongroup">

					<button
						class="button icon icon--download"
						:aria-label="getL10nFromConfig('download')"
						@click="download"
					>
						{{getL10nFromConfig('download')}}
					</button>

					<!--
					<button
						v-if="$utility.hasClipboard()"
						class="button icon icon--copy"
						@click="clipboard"
					>
							{{getL10nFromConfig('getSharingLink')}}
					</button>
					-->
				</footer>

			</div>
		</div>

	</Modal>

</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Modal from "../General/Modal";
import ExportManagerCart from "./ExportManagerCart";
import cartService from '../../services/cartService';

export default {

    name: "ExportManager",

	components: {
    	Modal,
        ExportManagerCart
	},

	data: function(){
		return {
			buttonProgress: 'em__button--progress',
			buttonSuccess: 'em__button--success',
			buttonFail: 'em__button--fail',
		}
	},

	methods: {
        ...mapActions('generator', ['showExportManager', 'addCustomizationProperty', 'addCustomizationToCart', 'getDownload']),

		setColorSelection() {
			this.addCustomizationProperty ({
				controlId: 'selectedColors',
				values: this.$utility.clone(this.colors)
			});
		},

		addToCart(){
			this.addCustomizationToCart(this.customization);
			this.showExportManager([false]);
		},

		/**
		 * Copy Package-Link to Clipboard
		 * */
		clipboard(e) {
        	e.preventDefault();

			if(this.isDownloading || !this.$utility.hasClipboard()) return;

			const button = e.currentTarget;
			button.classList.add(this.buttonProgress);

			return this.getDownload()
				.then((r) => {

					if(r.status !== 200) throw new Error(r.statusText);
					if(r.data.status !== 'ok') throw new Error(r.data.message);

					return this.$utility.clipboardSet(r.data.link.httpUrl.toString())
						.then(() => {
							button.classList.add(this.buttonSuccess);
						})
					;
				})

				.catch((err) => {
					button.classList.add(this.buttonFail);
					console.warn(err);
				})

				.finally(() => {
					button.classList.remove(this.buttonProgress);
					setTimeout(
						() => { button.classList.remove(...[this.buttonSuccess, this.buttonFail]);},
						2000);
				})
			;

		},

		/**
		 * Download the Package
		 */
		download(e) {
			if(this.isDownloading) return;

			const button = e.currentTarget;
			button.classList.add(this.buttonProgress);

			return this.getDownload()
				.then((r) => {
					if(r.status !== 200) throw new Error(r.statusText);
					if(r.data.status !== 'ok') throw new Error(r.data.message);
					this.$utility.downloadFile(r.data.link.httpUrl.toString());

					//Tracking: Downloads per Generator
					const cartItems = cartService.getCartForDownload();
					const generatorNames = [];
					cartItems.forEach(function(i) {
						if(i.active){
							generatorNames.push(i.type);
						} 
					});
					if(this.isTracking){
						if(generatorNames.length > 1){
							_satellite.track("GeneratorBulkFileDownload",{"generatorNames": generatorNames });
						} else {
							_satellite.track("GeneratorFileDownload",{"generatorName": generatorNames[0] });
						}	
					} else {
						if(generatorNames.length > 1){
							console.log('Tracking Demo: GeneratorBulkFileDownload > generatorNames:', generatorNames );
						} else {
							console.log('Tracking Demo: GeneratorFileDownload > generatorName:', generatorNames[0] );
						}	
					}
				})

				.catch((err) => {
					button.classList.add(this.buttonFail);
					console.warn(err);
				})

				.finally(() => {
					button.classList.remove(this.buttonProgress);
					setTimeout(
						() => { button.classList.remove(...[this.buttonSuccess, this.buttonFail]);},
						2000);
				});

		},

	},

	computed: {
		...mapState('generator', ['isAllInOne', 'isDownloading', 'isShowingExportManager', 'exportManagerModalContent', 'generalConfig', 'customization', 'generator', 'cart', 'isTracking']),
		...mapGetters ('generator', ['getL10nFromConfig']),

		title: function(){
			return this.isAllInOne ? this.getL10nFromConfig('exportManagerDefaultTitle') : this.generator.config['exportTitle'];
		}

	},
	props: {
		colors: Array
	}
};
</script>

<style lang="scss" scoped>

	.em {

		$root: &;

		&__button {
			@include icon-setting(':before');

			&--success {
				@include icon-content('check', ':before', true)
			}
			&--progress {
				@include icon-content('reload', ':before', true);
				@include icon-spin(true, ':before');
			}
			&--fail {
				@include icon-content('cross', ':before', true)
			}

		}
	}

</style>




const initialState = {
	mapping: {

		"merckgenerators.local:8080": "merckgenerators.local",
		"brand-hub-frontend.merckgroup.local": "brand-hub-backend.merckgroup.local",
		"merckgeneratorsUS.local:8080": "merckgeneratorsUS.local",

		"f.merck.neuerituale.net": "merck.neuerituale.net",

		"brand-hub-frontend.merckgroup.com": "brand-hub-backend.merckgroup.com",
		"brand-hub-frontend.emdgroup.com": "brand-hub-backend.emdgroup.com",

		"dev-brand-hub-frontend.merckgroup.com": "dev-brand-hub-backend.merckgroup.com",
		"dev-brand-hub-frontend.emdgroup.com": "dev-brand-hub-backend.emdgroup.com"
	},
	tracking: {

		//"merckgenerators.local:8080": "merckgenerators.local", //uncomment for local tracking debugging

		"brand-hub-frontend.merckgroup.com": "assets.adobedtm.com/b5a2629b807e/818100b56afe/launch-5f50de6f2797.min.js",
		"brand-hub-frontend.emdgroup.com": "assets.adobedtm.com/b5a2629b807e/818100b56afe/launch-5f50de6f2797.min.js",

		"dev-brand-hub-frontend.merckgroup.com": "assets.adobedtm.com/b5a2629b807e/818100b56afe/launch-dec9589db256-staging.min.js",
		"dev-brand-hub-frontend.emdgroup.com": "assets.adobedtm.com/b5a2629b807e/818100b56afe/launch-dec9589db256-staging.min.js"
	}
};

const getters = {
	getBaseEndpoint: state => {
		const host = window.location.host;
		if(state.hasOwnProperty('mapping') && state.mapping.hasOwnProperty(host)){
			return '//' + state.mapping[host];
		} else {
			return false;
		}
	},
	getTracking: state => {
		const host = window.location.host;
		if(state.hasOwnProperty('tracking') && state.tracking.hasOwnProperty(host)){
			return state.tracking[host];
		} else {
			return false;
		}
	}
}

export default {
	namespaced: true,
	state: initialState,
	getters
}

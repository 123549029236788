import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from './services/axios.js';

import {longClickDirective} from 'vue-long-click';
import utility from "./utility/utility";

import VueMoment from 'vue-moment';
const moment = require('moment');
import 'moment/locale/de'
import 'moment/locale/en-gb'

/**
 * Click outside
 * https://stackoverflow.com/a/42389266
 */
Vue.directive('click-outside', {
	bind: function(el, binding, vnode) {
		el.clickOutsideEvent = function(event) {
			if(!(el === event.target || el.contains(event.target)))
				vnode.context[binding.expression](event);
		};
		document.body.addEventListener('click', el.clickOutsideEvent)
	},
	unbind: function(el) {
		document.body.removeEventListener('click', el.clickOutsideEvent)
	},
});

/**
 * Long click
 * https://github.com/ittus/vue-long-click
 */
const longClickInstance = longClickDirective({delay: 400, interval: 50});
Vue.directive('click-long', longClickInstance);

/**
 * Utilities
 */
Vue.use(utility);

/**
 * Moment.js
 */
Vue.use(VueMoment, {moment});

/**
 * Run
 */
Vue.config.productionTip = false;
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');

<template>
	<div class="output">
        <slot></slot>
    </div>
</template>

<script>
export default {
	name: "Output"
};
</script>

import axios from './axios.js';
import store from '../store';

const funcs = {

	fetchGeneralConfig: () => {
		const urlParams = new URLSearchParams(window.location.search);

		return axios.get('config', { params: {l: urlParams.get('l')} })
			.then(res => res.data)
			.catch(function (error) {
				alert('Can\'t load global-config, please check the server configuration.');
				console.log('ERR', error.toJSON());
			});
	},

	fetchGeneratorConfig: (generator) => {
		const urlParams = new URLSearchParams(window.location.search);
		return axios.get(generator, { params: {l: urlParams.get('l')} })
			.then (res => res.data)
			.catch (function(error) {
				alert('Can\'t load global-config, please check the server configuration.');
				console.log('ERR', error.toJSON());
			});
	},

	getStorageName(){

		let storageName = store.state.generator.generator.config.name;

		if(store.state.generator.isAllInOne){
			storageName = 'allinone_' + storageName;
		} else {
			storageName = 'standalone_' + storageName;
		}

		// add context
		if(store.state.generator.context){
			storageName += '_' + store.state.generator.context.toLowerCase();
		}

		return storageName;
	},

	fetchCustomizationFromLocalStorage: () => {
		return JSON.parse(localStorage.getItem(funcs.getStorageName()));
	},

	saveCustomizationToLocalStorage: (generator, customization) => {
		localStorage.setItem(funcs.getStorageName(), JSON.stringify({customization: customization}));
	},

	clearLocalStorage: () => {
		localStorage.clear();
	},

	/**
	 * @param request
	 * @returns Promise
	 */
	generate: (request) => {
		const urlParams = new URLSearchParams(window.location.search);
		return axios.post(`generate`, request, {params: {l: urlParams.get('l')}, withCredentials: true});
	}
}

export default funcs

<template>
    <header class="navigation" v-if="isAllInOne">
	    <div class="container">
		    <ul class="navigation__section navigation__section--start navigation__list">
			    <li v-for="route in routes">
				    <router-link
					    :to="route"
					    class="navigation__link"
					    active-class="navigation__link--active">
					    {{ getL10nFromConfig('navigationTitle' + route.title.replace(/\s/g, '')) || route.title}}
				    </router-link>
			    </li>
		    </ul>

		    <ul class="navigation__section navigation__section--end navigation__list">
				<li>
					<ExportManagerCartButton/>
				</li>
		    </ul>
	    </div>
    </header>
</template>
<script>

import {mapGetters, mapState} from 'vuex';
import ExportManagerCartButton from "../Outputs/ExportManagerCartButton";

export default {
    name: 'Navigation',
	components: {
    	ExportManagerCartButton
	},

	computed: {

    	...mapState('generator', ['isAllInOne', 'context']),
		...mapGetters('generator', ['getL10nFromConfig']),

		routes() {

			let routeOptions = this.$router.options.routes.find( (route) => {
				return route.path === '/generator';
			});

			let routes = [];
			if(routeOptions.hasOwnProperty('children')) {

				// filter context
				const children = routeOptions.children.filter((child) => {
					let include = true;
					if(
						child.hasOwnProperty('meta') &&
						child.meta.hasOwnProperty('blacklist') &&
						child.meta.blacklist.includes(this.context)
					){
						include = false;
					}
					return include;
				});

				for (let i in children) {
					if (!children.hasOwnProperty(i)) { continue; }
					const route = children[i];
					if(route.hasOwnProperty('title')) {
						// forward GET params
						route.query = this.$route.query;
						routes.push(route);
					}
				}
			}
			return routes;
		}
	}
};
</script>

<style scoped lang="scss">

	.navigation {
		$height: $gutter;

		position: relative;
		width: 100%;
		height: $height;

		margin-top: -$height;
		@at-root .header ~ & { bottom: $navbar-height + $half-gutter }

		@include __ {
			.container {
				height: 100%;
				display: flex;
				justify-content: space-between;
			}
		}

		&__section {
			line-height: 200%;
		}

		&__list {
			display: flex;
			height: 100%;
			padding: 0 $half-gutter;
		}

		&__item {
			display: contents;
			background-color: lime;
		}

		&__link {
			display: block;
			padding: 0 $half-gutter;
			height: 100%;

			white-space: nowrap;
			font-size: $font-size-big;
			font-weight: $font-weight-bold;
			text-transform: uppercase;

			color: rgba($white, .3);

			&--active { color: $white; }
		}

		&__downloadlink {
			margin: 0 $half-gutter;

		}

	}

</style>

<template>

	<div class="inputmanager container">

		<!-- tab -->
		<nav class="navbar" :class="{ 'is-all-in-one': isAllInOne === true }">
			<div class="navbar__section navbar__section--start">
				<div class="tabs">
					<!-- All In One -->
					<div
						v-if="isAllInOne === true"
						class="tabs__item"
						:class="{'tabs__item--selected':isShowingGeneratorIntro}"
						@click="toggleIntroAndSelectTab(true, -1)"
					>
						<h3>{{getL10nFromConfig('intro')}}</h3>
					</div>

					<!-- tab names -->
					<div
						v-for="(tab, i) in generator.config.tabs"
						:key="tab.title"
						class="tabs__item"
						:class="getSelectedClass(i)"
						@click="selectTab(tab,i)"
						v-if="generator.config.tabs.length > 1 || isAllInOne"
						ref="tabs"
					>
						<h3>{{ tab.title }}</h3>
					</div>
				</div>
			</div>
			<div class="navbar__section navbar__section--end">
				<div class="buttongroup" v-if="isRandomizable">
					<button class="button button--inverted button--hoverspin icon icon--reload" @click="setRandomize()">{{getL10nFromConfig('randomize')}}</button>
				</div>
				<ExportManagerToolbar v-if="isExportable" />
			</div>
		</nav>

		<!-- tab content -->
		<div v-for="(tab, i) in generator.config.tabs" :key="tab.id" class="controls" v-show="tabSelected === i && notEmpty(tab)">
			<slot
				:name="tab.id"
				:tab="tab">{{ tab.title }}
			</slot>
		</div>

		<!-- here we can add custom inputs -->
		<slot name="area-bottom"></slot>
	</div>
</template>

<script>
	import {mapState, mapActions, mapGetters} from 'vuex';
	import ExportManagerToolbar from "../Outputs/ExportManagerToolbar";

	export default {
		name: 'TabManager',

		components: {
			ExportManagerToolbar
		},

		props: {
			isExportable: {
				type: Boolean,
				default: true
			}
		},

		data () {
			return {
				cmp: "dropdown",
				tabSelected: 0,
				cachedCustomization: {},
			}
		},

		watch: {
			customization (values) {
				if(values) {
					this.update(values);
					this.cachedCustomization = values;
				}
			}
		},

		computed: {
			...mapState ('generator', ['generator', 'customization', 'cart', 'isShowingGeneratorIntro', 'isAllInOne']),
			...mapGetters ('generator', ['getCustomizationByControlId', 'getCustomizationFirstValueByControlId', 'getL10nFromConfig']),

			isRandomizable(){
				return (
					this.generator.config.tabs[this.tabSelected] &&
					this.generator.config.tabs[this.tabSelected].hasOwnProperty('randomize') &&
					this.generator.config.tabs[this.tabSelected]['randomize'] === true &&
					!this.isShowingGeneratorIntro
				);
			}

		},

		methods: {
			...mapActions ('generator', ['addCustomizationProperty', 'addCustomizationToCart', 'showExportManager', 'showGeneratorIntro', 'setRandomize']),

			selectTab (tab, i) {
				this.tabSelected = i;
				this.showGeneratorIntro(false);
				this.addCustomizationProperty({controlId: 'tabSelected', values: [i]})
			},

			hideTab(i) {
				if(this.$refs && this.$refs.hasOwnProperty('tabs') && this.$refs.tabs.hasOwnProperty(i)){
					this.$refs.tabs[i].style.display = 'none';
					this.generator.config.tabs[i]['hidden'] = true;
				}
			},

			getSelectedClass(i){
				return !this.isShowingGeneratorIntro && this.tabSelected === i ? 'tabs__item--selected':'';
			},

			toggleIntroAndSelectTab(show = true, i = null){
				if(i === null){
					// find first visible tab
					i = this.generator.config.tabs.findIndex((tab) => {
						return tab?.hidden !== true;
					});
				}
				this.showGeneratorIntro(show);
				this.addCustomizationProperty({controlId: 'tabSelected', values: [i]})
			},

			notEmpty (tab) {
				return tab.controls.length > 0;
			},

			init () {
				this.update(this.cachedCustomization);
			},

			update(customizations) {
				customizations.forEach (c => {
					switch (c.controlId) {
						case 'tabSelected':
							this.tabSelected = c.values[0];
							break;
					}
				});
			},
		},

		mounted () {
			if(!this.getCustomizationByControlId('tabSelected')){
				this.addCustomizationProperty({controlId: 'tabSelected', values: [this.generator.config.tabSelected]});
			}
			this.tabSelected = this.getCustomizationFirstValueByControlId('tabSelected');

			this.cachedCustomization = this.customization;
			this.init(this.customization);
		}

	}
</script>

<style lang="scss">

	.inputmanager {

		@at-root .header ~ & {
			position: relative;
			margin-top: -#{$navbar-height};
			background-color: $white;
		}

	}

	.generator--colors .navbar {
	 	display: none;
	 }
	.generator--colors .navbar.is-all-in-one {
	 	display: flex;
	}

</style>

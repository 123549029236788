<template>
	<div class="dropdown" :class="{'dropdown--open': isDropDownShown}">

		<!-- visible element -->
		<button
			@click="toggleDropDown"
			v-click-outside="closeDropDown"
			class="dropdown__item dropdown__item--selected"
			ref="button"
		>
			<slot name="button-prepend" :item="selectedItem" />
			<label class="label">{{ subtitle }}</label>
			<slot name="button" :item="selectedItem" />
		</button>

		<!-- dropdown -->
		<ul class="dropdown__list" v-show="isDropDownShown" :style="isDropDownShown ? 'max-height:' + maxheight() + 'px' : ''">
			<slot name="list-prepended" />
			<li v-for="(item, i) in items" :key="i" @click="selectItem(i)">
				<slot name="list" :item="item" />
			</li>
			<slot name="list-appended" />
		</ul>

	</div>
</template>

<script>
	export default {
		name: "Dropdown",
		props: {
			name: String,
			items: Array,
			title: String,
			subtitle: String,
			selected: {
				type: String|Number,
			}
		},

		data() {
			return {
				selectedIndex: 0,
				isDropDownShown: false
			}
		},

		computed: {
			selectedItem() {
				return this.items.slice(this.selectedIndex, this.selectedIndex + 1)[0];
			}
		},

		methods: {

			selectItem(i) {
				this.selectedIndex = i;
				this.isDropDownShown = false;
			},

			getState(i) {
				return this.selectedIndex === i;
			},

			toggleDropDown() {
				this.isDropDownShown = !this.isDropDownShown
			},

			closeDropDown() {
				this.isDropDownShown = false;
			},

			maxheight() {
				return 500;
			},

			getSelected() {
				this.items.forEach((c, index) => {
					if(this.selected === c) {
						this.selectedIndex = index;
					}
				})
			}
		},

		watch: {
			// we need to have it in a watcher since the data might arrive
			// a bit after the component is mounted
			selected(value) {
				this.getSelected();
			}
		},

		mounted() {
			this.orderedItems = [...this.items];
			this.getSelected();
		}
	};
</script>

<style scoped lang="scss">

	.dropdown {

		$root: &;
		$compact-height: rem-calc(50);

		@mixin large {
			@at-root #{$root}--large & {
				@content
			}
		}
		@mixin small {
			@at-root #{$root}--small & {
				@content
			}
		}
		@mixin compact {
			@at-root #{$root}--compact & {
				@content
			}
		}
		@mixin open($child: ' '#{&}) {
			@at-root #{$root}--open#{$child} {
				@content
			}
		}

		position: relative;

		&--large {
			min-width: rem-calc(230);
		}

		&--compact {
			height: $dropdown-compact-height;
		}

		label {
			margin-bottom: rem-calc(4);
		}

		&__list {

			$list: &;

			position: absolute;
			z-index: 10;

			background-color: $white;
			border: 1px solid $light;
			transform: translateY(-1px);

			overflow-x: hidden;
			overflow-y: auto;

			/** TODO: set dynamic with js */
			@include large {
				width: calc(100% - 2px); /* border */
				max-height: rem-calc(300);
			}

			@include compact {
				transform: translate(0, calc(-100% - #{$compact-height}));
				padding-bottom: rem-calc(8);
				right: rem-calc(-22);

			}

		}

		&__item {

			$item: &;

			@include is-input;
			@include is-noselect;

			display: flex;
			cursor: pointer;

			white-space: nowrap;
			border-bottom: 1px solid $light;

			padding: 0 rem-calc(24);

			@include small {
				height: rem-calc(50);
				min-width: rem-calc(160);
			}

			@include compact {
				min-width: 0;
				//height: rem-calc(50) !important;
				border-bottom: none;
				label { display: none }
			}

			@at-root #{$root}--icon & {
				flex-direction: row;
				justify-content: left;
				align-items: center;
				padding-left: 0;
			}

			svg,
			img {
				margin-right: $half-gutter;
				margin-left: $half-gutter;
			}

			&:focus {
				outline: none;
				background-color: $light;
			}

			@include hover-or-active {
				background-color: $light;
			}

			&--selected {
				border: 1px solid $light;

				@at-root #{$root}--icon & {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: baseline;
					padding-left: rem-calc(50) + $half-gutter*2;

					svg, img {
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
					}
				}

				@include icon('arrow-down', ':after') {
					position: absolute;
					right: $half-gutter;

					pointer-events: none;
					touch-action: none;
					color: $silver;

					transition: transform $speed-fast;
					transform-origin: center;

					@include open {
						transform: rotate(-180deg);
					}
				}

				@include small {
					height: rem-calc(24);
					min-width: 0;
					width: auto;
					padding: 0 $gutter-normal/2 0 rem-calc(7);

					font-size: $font-size-smallprint-uppercase;
					font-weight: $font-weight-bold;
					text-transform: uppercase;
					color: $silver;

					&:after {
						font-size: $icon-size-small;
						right: $half-gutter/2;
					}

					* {
						display: none !important;
					}
				}

				@include compact {

					@include is-label;
					display: inline-flex;
					box-sizing: border-box;
					height: 100%;

					padding-left: $half-gutter;
					background-color: $middle-gray;
					border: none!important;
					color: $white;

					overflow: hidden;

					@at-root :not(#{$root}--open)#{&} {

					}

					@include hover-or-active {
						> * {
							background-color: transparent!important;
							border-color: transparent;
						}
					}

					@include not-hover-or-active {
						width: $dropdown-compact-height;
						padding: 0!important;
						background-color: transparent;
						&:after {
							color: var(--contrast, $white);
						}
					}

					@include open(&) {
						> * {
							background-color: $middle-gray!important;
							border-color: transparent;
						}
					}

					&:after {
						right: rem-calc(12);
						width: $icon-size-normal;
						color: $white;
						@include open(&) {
							color: $white!important;
						}
					}

				}

			}

			&--footer {
				@include is-label;

				display: flex;
				flex-wrap: nowrap;
				min-height: rem-calc(60);
				height: auto;

				font-size: $font-size-smallprint-uppercase;
				font-weight: $font-weight-bold;
				text-transform: uppercase;
				color: $silver;

				@include small {
					min-height: 0;
				}
			}

			@at-root #{$root}__list {
				> :last-child, :last-child #{$item} {
					border-bottom: none !important;
				}
			}

			@at-root #{$root}--required#{$root}--alert &--selected {
				position: relative;
				z-index: 15;
				color: $alert;
				border-color: $alert;
			}

		}

	}

</style>

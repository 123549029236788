import Vue from 'vue';
import Vuex from 'vuex';

import generatorStore from './generatorStore';
import notificationStore from './notificationStore';
import routerStore from './routerStore';

Vue.use (Vuex);

export default new Vuex.Store ({
	modules: {
		generator: generatorStore,
		notification: notificationStore,
		router: routerStore
	}
})

import store from '../store';
import utility from '../utility/utility';
import formatters from '../formatters/formatters';

const funcs = {

	loadCart: () => {
		let cart = funcs.getCart();
		if(cart){
			store.commit('generator/setCart', JSON.parse(cart));
		}
	},

	addCustomization: (generator, customization) => {

		let cart = funcs.getCart();
		cart = (cart == null) ? [] : JSON.parse(cart);

		cart.push({
			title: generator.config.title,
			name: generator.config.name,
			ts: +new Date(),
			active: true,
			customization: customization
		});

		localStorage.setItem('cart', JSON.stringify(cart));
		store.commit('generator/setCart', cart);
	},

	updateCustomization(customization){
		let cart = store.state.generator.cart;

		let item = cart.find((stored) => {
			return stored.ts === customization.ts;
		});

		if(item){
			item = customization;
			funcs.saveCartToLocalStorage(cart);
		}
	},

	deleteCustomization(customization){
		let cart = store.state.generator.cart;

		let filtered = cart.filter((stored) => {
			return stored.ts !== customization.ts;
		});

		if(filtered){
			funcs.saveCartToLocalStorage(filtered);
		}
	},

	saveCartToLocalStorage(data){
		localStorage.setItem('cart', JSON.stringify(data));
		store.commit('generator/setCart', data);
	},

	clearLocalStorage: () => {
		localStorage.clear();
	},

	getCart(){
		return localStorage.getItem('cart');
	},

	/**
	 * Prepare Cart items for Downloading
	 * @param customizations
	 * @param formatterName
	 * @returns {{}}
	 */
	prepareCartItemForDownload(customizations, formatterName) {

		let result = {};
		if(!customizations || !formatters.hasOwnProperty(formatterName)) return result;

		const formatter = formatters[formatterName];

		customizations.forEach((c) => {
			if(typeof formatter[c.controlId] === 'function') {
				try {
					result = {
						...result,
						...formatter[c.controlId].call(null, c, customizations) ?? {}
					}
				} catch(e) {/* ignore */ }
			}
		});
		return result;
	},

	/**
	 * Get Cart for Downloading
	 * @returns {[]}
	 */
	getCartForDownload() {

		let cartItems = [];

		/**
		 * Cart (All in one)
		 */
		if(
			store.state.generator.isAllInOne &&
			store.state.generator.exportManagerModalContent !== 'downloadStandalone')
		{
			store.state.generator.cart.forEach((item) => {
				const preparedItem = funcs.prepareCartItemForDownload(item.customization, item.name);
				if(!utility.isObjectEmpty(preparedItem)) {
					cartItems.push({
						type: item.name,
						ts: item.ts,
						active: item.active,
						data: preparedItem
					});
				}
			});
		}

		/**
		 * Standalone
		 */
		else {
			const preparedItem = funcs.prepareCartItemForDownload(
				store.state.generator.customization,
				store.state.generator.generator.config.name
			);

			// add item to request array
			cartItems.push({
				type: store.state.generator.generator.config.name,
				ts: +new Date(),
				active: true,
				data: preparedItem
			});
		}

		return cartItems;
	}

}

export default funcs;

import baseService from '../services/baseService';
import cartService from '../services/cartService';
import validators from '../validators/validators';
import utility from "~/utility/utility";

const initialState = {
	baseEndpoint: '',
	generalConfig: {
		formats: [],
		colors: [],
		units: []
	},
	isGeneralConfigLoaded: false,

	generator: {
		config: {
			assets: '',
			path: '',
		},
		tabs: []
	},
	customization: [],
	cart: [],
	cartMaxItems: 10,

	isLoading: false,
	isDownloading: false,
	isConfigured: false,

	randomize: false,

	isShowingExportManager: false,
	exportManagerModalContent: '',
	isShowingExportManagerCart: false,

	isShowingModal: false,
	isAllInOne: false,
	isShowingGeneratorIntro: false,
	isValidFormat: true,
	isValidProportions: true,

	context: '',

	isTracking: true,

	trackedGenerators: []
};

const mutations = {

	setBaseEndpoint: (state, baseEndpoint) => {
		state.baseEndpoint = baseEndpoint;
	},

	setGeneralConfig: (state, config) => {
		state.generalConfig = config;
		state.isGeneralConfigLoaded = true;
	},

	setGenerator: (state, { getters, generator }) => {

		state.generator = generator;

		generator.config.tabs.forEach(tab => {

			if(!Array.isArray(tab.controls)) return;

			tab.controls.forEach(control => {

				if(control.hasOwnProperty('id')){

					const customizationProperty = getters.getCustomizationByControlId(control.id);

					if(
						typeof customizationProperty === 'undefined' &&
						control.selected
					){
						state.customization.push({ controlId: control.id, values: [control.selected] });
					}
				}
			});
		});
	},

	clearCustomization: (state) => {
		state.customization = [];
	},

	clearGenerator: (state) => {
		state.generator = null;
		state.generator = utility.clone(initialState.generator);
	},

	setCustomizationProperty: (state, property) => {
		let filtered = state.customization.filter(el => el.controlId !== property.controlId);
		filtered.push(property);
		state.customization = filtered;
	},

	deleteCustomizationProperty: (state, o) => {
		state.customization = state.customization.filter(el => el.controlId !== o.controlId);
	},

	setLoading: (state, status) => {
		state.isLoading = status;
	},

	setCart: (state, cart) => {
		state.cart = cart;
	},

	clearCart: (state) => {
		state.cart = [];
	},

	setShowExportManager: (state, b) => {
		state.isShowingExportManager = b;
	},

	setExportManagerModalContent: (state, content) => {
		state.exportManagerModalContent = content;
	},

	setShowGeneratorIntro: (state, b) => {
		state.isShowingGeneratorIntro = b;
	},

	setShowModal: (state, b) => {
		state.isShowingModal = b;
	},

	setIsAllInOne: (state, b) => {
		if(b) {
			state.isAllInOne = true;
		}
	},

	setContext: (state, context) => {
		if(
			state.hasOwnProperty('generalConfig') &&
			state.generalConfig.hasOwnProperty('contexts') &&
			state.generalConfig.contexts.includes(context.toUpperCase())
		){
			state.context = context.toUpperCase();
		}
	},

	setRandomize: (state) => {
		state.randomize = true;
		window.setTimeout(() => {
			state.randomize = false;
		}, 0);
	},

	setDownloading: (state, b) => {
		state.isDownloading = b;
	},

	setIsConfigured: (state, b) => {
		state.isConfigured = b;
	},

	setIsValidFormat: (state, b) => {
		state.isValidFormat = b;
	},

	setIsValidProportions: (state, b) => {
		state.isValidProportions = b;
	},

	setIsTracking: (state, b) => {
		state.isTracking = b;
	}

};

const getters = {

	getGeneratorIsConfigured(state){
		return state.generator.isConfigured;
	},

	getIsGeneralConfigLoaded: state => {
		return state.isGeneralConfigLoaded;
	},

	getFormatFromConfig: (state, getters) => format => {
		let retFormat = state.generalConfig.formats.hasOwnProperty(format) ? state.generalConfig.formats[format] : null;
		if(retFormat && format === 'custom') {
			const val = getters.getCustomizationByControlId('format');
			if(val) {
				retFormat.width = val.values[1];
				retFormat.height = val.values[2];
				retFormat.unit = val.values[3];
			}
		}
		return utility.clone(retFormat);
	},

	getColorFromConfig: state => colorKey => {
		return state.generalConfig.colors.hasOwnProperty(colorKey) ? utility.clone(state.generalConfig.colors[colorKey]) : null;
	},

	getL10nFromConfig: (state, getters) => l10nKey => {
		return state.isGeneralConfigLoaded && state.generalConfig.l10n.hasOwnProperty(l10nKey) ? utility.clone(state.generalConfig.l10n[l10nKey]) : '';
	},

	assetsUrl: (state, axios) => {
		return state.generator.config.assets !== '' ? state.baseEndpoint + '/' + state.generator.config.assets : '';
	},

	getGeneratorConfig: state =>  {
		return utility.clone(state.generator.config);
	},

	getCustomizationByControlId: state => id => {
		let prop = state.customization.filter((c) => c.controlId === id);
		return prop ? prop[0] : null;
	},

	getCustomizationValueByControlId: (state, getters) => (id, fallbackValue = []) => {
		const prop = getters.getCustomizationByControlId(id);
		return (
			prop
			&& prop.hasOwnProperty('values')
			&& (Array.isArray(prop.values) || utility.isPlainObject(prop))
		) ? prop.values : fallbackValue;
	},

	getCustomizationFirstValueByControlId: (state, getters) => (id, fallbackValue = '') => {
		let values = getters.getCustomizationValueByControlId(id);
		return !(typeof values[0] === 'undefined' || typeof values == null) ? values[0] : fallbackValue;
	},

	getFormatRangeByUnit: (state) => (range, unit) => {
		if(
			utility.isNotEmptyString(range) &&
			utility.isNotEmptyString(unit) &&
			state.isGeneralConfigLoaded &&
			state.generalConfig.units.hasOwnProperty(unit) &&
			state.generalConfig.units[unit].hasOwnProperty(range)
		){
			return state.generalConfig.units[unit][range];
		}
		return 0;
	}

};

const actions = {

	loadGeneralConfig: ({commit, dispatch}) => {
		return baseService.fetchGeneralConfig()
			.then(res => {
				commit('setGeneralConfig', res);
			})
			.catch (err => {
				console.log('loadGeneralConfig error', err);
			})
	},

	loadGenerator: ({commit, state, dispatch, getters}, gen) => {

		commit('setLoading', true);
		commit('clearCustomization');
		commit('clearGenerator');

    	return baseService.fetchGeneratorConfig(gen)
			.then (generator => {


				commit('setGenerator', { getters, generator });

				// load customization from localStorage
				let stored = baseService.fetchCustomizationFromLocalStorage(generator);

				// create customization array from local storage data
				if (stored && stored.hasOwnProperty('customization')) {

					const validator = validators[state.generator.config.name];
					stored.customization.forEach((c) => {
						if(typeof validator !== 'undefined' && typeof validator[c.controlId] === 'function') {
							validator[c.controlId].call(null, c, stored);
						}
					});

					stored.customization.forEach(item => {
						commit('setCustomizationProperty', item);
					});
				}

				if(state.isAllInOne){
					dispatch('loadCart');
				}

				commit('setLoading', false);
			})
			.catch (err => {
				console.error('fetchGeneratorConfig Error');
			});
	},

	/**
	 * @param state
	 * @param commit
	 * @returns Promise
	 */
	getDownload: ({state, commit}) => {

		commit('setDownloading', true);

		const cartItems = cartService.getCartForDownload();
		return baseService.generate({cart: cartItems})
			.then(res => { return res; })
			.catch (err => {
				console.log('download error', err);
			})
			.finally(() => {
				commit('setDownloading', false);
			})
			;
	},

	saveCustomizationLocal: ({commit, state}) => {
		baseService.saveCustomizationToLocalStorage(state.generator, state.customization);
	},

	clearCustomizationLocal: ({commit, state}) => {
		baseService.clearLocalStorage();
		commit('clearCustomization');
	},

	clearCustomization: ({commit, state}) => {
		commit('clearCustomization');
	},

	clearGenerator: ({commit, state}) => {
		commit('clearGenerator');
	},

	addCustomizationProperty: ({state, commit, dispatch}, c) => {

		const validator = validators[state.generator.config.name];
		if(typeof validator !== 'undefined' && typeof validator[c.controlId] === 'function') {
			if(validator[c.controlId].call(null, c, state.customization) === false){
				return;
			}
		}

		commit('setCustomizationProperty', c);
		dispatch('saveCustomizationLocal');
	},

	removeCustomizationProperty: ({commit, dispatch}, prop) => {
		commit('deleteCustomizationProperty', prop);
		dispatch('saveCustomizationLocal')
	},

	loadCart: ({commit, state}) => {
		cartService.loadCart();
	},

	clearCart: ({commit, state}) => {
		commit('clearCart');
	},

	addCustomizationToCart: ({commit, state}) => {
		cartService.addCustomization(state.generator, state.customization);
	},

	updateCustomizationInCart: ({commit, dispatch, state}, customization) => {
		cartService.updateCustomization(customization);
	},

	deleteCustomizationInCart: ({commit, dispatch, state}, customization) => {
		cartService.deleteCustomization(customization);
	},

	setLoading: ({commit, state}, boolean) => {
		commit('setLoading', boolean);
	},

	showExportManager: ({commit, dispatch}, args) => {

		commit('setShowExportManager', args[0]);

		if(args[1]){
			dispatch('setExportManagerModalContent', args[1]);
		}

	},

	setExportManagerModalContent: ({commit}, content) => {
		commit('setExportManagerModalContent', content);
	},

	showGeneratorIntro: ({commit, state}, boolean) => {
		commit('setShowGeneratorIntro', boolean);
	},

	showModal: ({commit}, boolean) => {
		commit('setShowModal', boolean);
	},

	setRandomize: ({commit}, boolean) => {
		commit('setRandomize', boolean);
	}
}

export default {
	namespaced: true,
	state: initialState,
	mutations,
	getters,
	actions
}

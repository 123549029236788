import ResizeObserver from 'resize-observer-polyfill';

let utility = {};
export default utility = {

	install: function (Vue, options) {
		Vue.prototype.$utility = utility;
	},

	/**
	 * Randbool
	 * @returns {boolean}
	 */
	randBool: function() {
		return !!this.random();
	},

	/**
	 * Get Random
	 * @param min
	 * @param max
	 * @param decimals
	 * @returns {number}
	 */
	random: function( min = 0, max = 1, decimals = 0 ) {
		let result = min + Math.random() * ( max - min );
		return this.round(result, decimals);
	},

	/**
	 * Round
	 * @param float
	 * @param decimals
	 * @returns {number}
	 */
	round: function(float, decimals = 0 ){
		const x = Math.pow(10, parseInt(decimals, 10) );
		return Math.round(float * x) / x;
	},

	/**
	 * Random String
	 * @param length
	 * @returns {string}
	 */
	randomString(length = 10) {
		let result = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let charactersLength = characters.length;
		for ( let i = 0; i < length; i++ ) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	},

	/**
	 * @param s
	 * @returns {boolean|boolean}
	 */
	isNotEmptyString(s){
		return typeof s === 'string' && s.length > 0;
	},

	/**
	 * Is Object
	 * @link https://github.com/jonschlinkert/isobject
	 * @param obj
	 * @returns {boolean}
	 */
	isObject: function(obj) {
		return obj != null
			&& typeof obj === 'object'
			&& Array.isArray(obj) === false
			&& Object.prototype.toString.call(obj) === '[object Object]'
			;
	},

	/**
	 * Is Plain Object
	 * @link https://github.com/jonschlinkert/is-plain-objecty
	 * @param obj
	 * @returns {boolean}
	 */
	isPlainObject: function(obj) {

		if (this.isObject(obj) === false) return false;

		// If has modified constructor
		const ctor = obj.constructor;
		if (typeof ctor !== 'function') return false;

		// If has modified prototype
		const prot = ctor.prototype;
		if (this.isObject(prot) === false) return false;

		// If constructor does not have an Object-specific method
		if (prot.hasOwnProperty('isPrototypeOf') === false) return false;

		return true;
	},

	/**
	 * Is Vue Object Empty
	 * @param obj
	 * @returns {*}
	 */
	isObjectEmpty: function(obj) {
		return !Object.keys(obj).length;
	},

	/**
	 * Is Object iterable
	 * @param obj
	 * @returns {boolean}
	 */
	isIterable: function (obj) {
		if (obj == null) return false;
		return typeof obj[Symbol.iterator] === 'function';
	},

	/**
	 * Clone Object
	 * @param obj
	 * @returns {*}
	 */
	clone: function(obj) {
		return JSON.parse(JSON.stringify(obj));
	},

	/**
	 * ucFirst
	 * @param string
	 * @returns {string}
	 */
	ucFirst: function(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	},

	/**
	 * Preload Files
	 * @param files
	 * @returns {boolean}
	 */
	preloadFiles: function(files){
		if(typeof files === "object" && files.length){
			Object.keys(files).map((key) => {
				const file = new Image();
				file.src = files[key];
			});
			return true;
		} return false;
	},

	/**
	 * @param href
	 */
	downloadFile: function(href) {
		const anchor = document.createElement('a');
		anchor.href = href;
		anchor.download = href;
		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);
	},

	/**
	 * {0} is dead, but {1} is alive! {0} {2}".format("ASP", "ASP.NET")
	 * https://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format/4673436#4673436
	 * @param format
	 * @returns {*}
	 */
	sprintf: function(format) {
		let args = Array.prototype.slice.call(arguments, 1);
		return format.replace(/{(\d+)}/g, function(match, number) {
			return typeof args[number] != 'undefined'
				? args[number]
				: match
				;
		});
	},

	/**
	 * @param format
	 * @returns {number}
	 */
	diagonal: function(format) {
		return Math.sqrt(Math.pow(format.width,2) + Math.pow(format.height,2));
	},

	/**
	 *
	 * @param format
	 * @param baseWidth
	 * @param baseHeight
	 * @returns {{scaleX: number, scaleY: number}}
	 */
	getScaleFactor: function (format, baseWidth = 100, baseHeight = 100) {

		if(!format) format = { width: baseWidth, height: baseHeight };

		let result = {
			scaleX: format.width / baseWidth,
			scaleY: format.height / baseHeight
		};
		result.max = Math.max.apply(null, Object.values(result));
		result.min = Math.min.apply(null, Object.values(result));
		return result;
	},

	/**
	 * @param value
	 * @param r1 array
	 * @param r2 array
	 * @returns {*}
	 */
	mapNumberInRange: function(value, r1, r2) {
		return (value - r1[0]) * (r2[1] - r2[0]) / (r1[1] - r1[0]) + r2[0];
	},

	isNumberInRange(value, min, max){
		return typeof value === 'number' &&
			typeof min === 'number' &&
			typeof max === 'number' &&
			value >= min &&
			value <= max;
	},

	/**
	 *
	 * @param number
	 * @returns {boolean}
	 */
	isPositiveInteger: function(number) {
		return Number.isInteger(number) && number > 0 && number < Number.MAX_SAFE_INTEGER;
	},

	isPositiveNumber: function(number) {
		return typeof number === 'number' && number > 0;
	},

	/**
	 *
	 * @param array
	 * @param exclude either array containing keys or int
	 * @returns {*|boolean|boolean}
	 */
	getRandomArrayItem: function(array, exclude = null){
		if(array.length){
			const index = Math.floor(Math.random() * array.length);
			if(
				(Array.isArray(exclude) && exclude.includes(index)) ||
				(typeof exclude === 'number' && exclude === index)
			) {
				return this.getRandomArrayItem(array, exclude);
			}
			return array[index];
		}
		return false;
	},

	/**
	 * Calculate Contrast Color
	 * @param hexColor
	 * @param white
	 * @param black
	 * @returns {string}
	 */
	getContrastColor: function(hexColor = 'ffffff', white = 'white', black = '#8D8D92') {

		hexColor = hexColor.replace(/^#/, '');
		if(!hexColor) return '#8D8D92';

		if(hexColor.length === 3) {
			hexColor = hexColor[0] + hexColor[0] + hexColor[1] + hexColor[1] + hexColor[2] + hexColor[2];
		} else if(hexColor.length > 6) {
			hexColor = hexColor.substr(0,6);
		} else if(hexColor.length < 6) {
			hexColor = hexColor.str1(6 - hexColor.length, 6, '0');
		}

		const r = parseInt(hexColor.substr(0,2), 16);
		const g = parseInt(hexColor.substr(2,2), 16);
		const b = parseInt(hexColor.substr(4,2), 16);

		const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
		return (yiq >= 190) ? black : white;
	},

	/**
	 * Has Clipboard
	 * @returns {boolean}
	 */
	hasClipboard: function() {
		return false; /* deactivated */
		return !!window.navigator.clipboard;
	},

	/**
	 * Copy to clipboard
	 */
	clipboardSet: function(text) {
		return new Promise((resolve, reject) => {
			if(!navigator.clipboard) {
				console.info('Clipboard is not defined or you are not in secure context');
				reject('Clipboard is not defined or you are not in secure context');
			} else {
				navigator.clipboard.writeText(text).then(() => {
					resolve(text)
				}, (error) => {
					console.error('Could not copy text: ', error);
					reject('Could not copy text: ');
				});
			}
		});
	},

	findByProperty(haystack, needle, property = 'controlId'){
		return haystack.find(entry => {
			return entry[property] === needle;
		});
	},

	resizeObserve($el, callback){
		if(typeof ResizeObserver === 'function') {
			let observer = new ResizeObserver(entries => {
				for(let entry of entries) {
					callback($el);
				}
			});
			observer.observe($el);
		}
	},

	sendHeightToParentWindow($el){
		// filter out invisible/hidden elements
		if($el.offsetParent !== null) {
			//const height = document.documentElement.scrollHeight;
			const height = document.getElementById('app').offsetHeight;
			window.parent.postMessage(height, '*');
			//console.log('Set app height to *', height, '(Actual height is: ' + document.getElementById('app').offsetHeight + ')');
		}
	}

};

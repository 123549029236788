<template>
	<div class="buttongroup emt" v-if="!isShowingGeneratorIntro">
		<button
			class="button icon icon--download"
			:class="{'emt__add--inactive' : !isConfigured || !isValidFormat}"
			:aria-label="getL10nFromConfig('downloadHeader')"
			@click="showExportManager([true, 'downloadStandalone'])"
			v-if="getGeneratorConfig.name !== 'colors'"
		><span class="button__text">{{getL10nFromConfig('downloadHeader')}}</span></button>

		<button
			class="emt__add button icon icon--plus"
			:class="{'emt__add--inactive' : !isConfigured || !isValidFormat || cart.length >= cartMaxItems}"
			:aria-label="getL10nFromConfig('addToCart')"
			@click="addCustomizationToCart(customization)"
			v-if="isAllInOne && getGeneratorConfig.name !== 'colors'"
		>
			<span class="button__text">{{ getL10nFromConfig('addToCart') }}</span>
		</button>

	</div>
</template>

<script>
	import {mapState, mapActions, mapGetters} from 'vuex';

	export default {
		name: "ExportManagerToolbar",
		methods: {
			...mapActions('generator', ['addCustomizationToCart', 'showExportManager'])
		},
		computed: {
			...mapState('generator', ['customization', 'cart', 'cartMaxItems', 'isAllInOne', 'isShowingGeneratorIntro', 'isConfigured', 'isValidFormat', 'generalConfig']),
			...mapGetters ('generator', ['getL10nFromConfig','getGeneratorConfig'])
		}
	};
</script>

<style scoped lang="scss">
	.emt {
		&__add {
			&--inactive {
				opacity: .2;
				pointer-events: none;
				touch-action: none;
			}
		}
		.button {
			@include until(960px) {
				&__text {
					display: none;
				}
				&.icon:before {
					margin: 0;
				}
			}
		}
	}

</style>




<template>
	<div>
		<div class="modal__content">
			<div class="cart" v-if="cart.length">

				<div class="cart__item__content" v-if="cart.length >= cartMaxItems">
					{{getL10nFromConfig('fullList')}}
				</div>

				<div v-for="(item, key, index) in sortedCart" :key="key" class="cart__item">
					<div class="checkbox">
						<input
							type="checkbox"
							:checked="item.active"
							@change="updateCustomization(item)"
							:id="item.ts"
						>
						<span class="checkbox__checkmark" @click="updateCustomization(item)"></span>
					</div>

					<label class="cart__item__content" :for="item.ts">

						<div class="cart__item__header">
							<div class="cart__item__title">{{ item.title }}</div>
							<div class="cart__item__date">{{ getRelativeTime(item.ts) }}</div>
						</div>


						<div class="cart__item__customization">
							{{ getFormattedCustomizations(item) }}
						</div>
					</label>
				</div>
			</div>
			<div v-else>
				{{getL10nFromConfig('emptyList')}}
			</div>
		</div>

		<footer class="modal__footer buttongroup" v-if="activeItems.length">
			<button class="button" @click="setExportManagerModalContent('downloadAllinone')">{{getL10nFromConfig('getFiles')}}</button>
			<button class="button button--inverted" @click="deleteActiveCustomizationsInCart()">{{ deleteButtonLabel }}</button>
		</footer>

	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name: "ExportManagerCart",

	computed: {
		...mapState('generator', ['cart', 'cartMaxItems', 'cartMaxItems', 'generalConfig', 'exportManagerModalContent']),
		...mapGetters('generator', [
			'getL10nFromConfig',
			'getGeneratorConfig',
			'getColorFromConfig'
		]),

		deleteButtonLabel(){
			return this.getL10nFromConfig('delete') + ' (' + this.activeItems.length + ')';
		},

		sortedCart() {
			return this.cart.sort((a,b) => {
				return b.ts - a.ts;
			});
		},

		activeItems(){
			return this.cart.filter((item) => {
				return item.active;
			});
		},

	},

	methods: {
    	...mapActions('generator', ['updateCustomizationInCart', 'deleteCustomizationInCart', 'setExportManagerModalContent', 'addCustomizationToCart']),

		updateCustomization(item){
			item.active = !item.active;
			this.updateCustomizationInCart(item);
		},

		deleteActiveCustomizationsInCart(){

    		for(let item of this.activeItems){
    		    this.deleteCustomizationInCart(item);
		    }
		},

		getColorNamesFromCustomization(customization, controlId = 'colors') {

			let colors = customization.find((item) => {
				return item.controlId === controlId;
			});


			let result = [];
			if(colors && colors.hasOwnProperty('values')){
				colors.values.forEach((key) => {
					const color = this.getColorFromConfig(key);
					if(color) result.push(color.name);
				})
			}
			return result;
		},

    	getFormattedCustomizations(item){

			let data = [];
			let result = '';
		    let customization = this.$utility.clone(item.customization);

	        switch(item.name){
		        case 'colors':
			        data.push(...this.getColorNamesFromCustomization(customization, 'selectedColors'));
			        result += data.join(", ");
			        break;
	            case 'string':
	            case 'vibrantm':
			        data.push(...this.getColorNamesFromCustomization(customization));
			        result += data.join(", ");
			        break;
	            case 'logo':
			        data.push(...this.getColorNamesFromCustomization(customization, 'color'));
			        data.push(this.getPositionFromCustomization(customization))
			        data.push(this.getFormatFromCustomization(customization));
			        result += data.join(", ");
		        	break;
		        case 'cells':
			        data.push(...this.getColorNamesFromCustomization(customization));
			        data.push(this.getFormatFromCustomization(customization));
			        result += data.join(", ");
		        	break;
	        }

		    return result;
	    },

		getPositionFromCustomization(customization) {
    		let position = customization.find(item => item.controlId === 'logoPosition');
    		return this.getL10nFromConfig('position') + ': ' + this.getL10nFromConfig(position.values[0])
		},

		getFormatFromCustomization(customization) {

    		let result = '';

			let format = customization.find((item) => {
				return item.controlId === 'format';
			});

			if(format && format.hasOwnProperty('values')){
				const formatData = this.generalConfig.formats.hasOwnProperty(format.values[0]) ? this.generalConfig.formats[format.values[0]] : null;
				if(formatData){
					result += formatData.title;

					if(format.values[0] === 'custom') {
						result += ' (' + format.values[1]*1 + 'x' + format.values[2]*1 + ' ' + format.values[3]  + ')'
					}


				}
			}
			return result;
		},

		/**
		 * Relative Timestamp with moment.js
		 * @param ts
		 * @returns {*}
		 */
		getRelativeTime(ts) {
    		let moment = this.$options.filters['moment'];
    		return + new Date() - ts < 172800000
				? moment(ts , 'from')
				: moment(ts , 'LLLL')
			    ;
		}

	}
};
</script>

<style scoped lang="scss">
	.cart {
		&__item {
			display: flex;
			align-items: center;
			padding: $half-gutter 0;
			font-size: $font-size-normal;
			line-height: $line-height-normal;

			&:not(:last-child){
				border-bottom: 1px solid #555555;
			}

			&__content {
				cursor: pointer;
				display: block;
				flex: 1;
			}

			&__header {
				display: flex;
				justify-content: space-between;
			}

			&__customization {
				max-width: 80%;
			}

			&__title {
				font-weight: $font-weight-bold;
			}

			&__date {
				font-size: $font-size-small;
				color: $silver;
			}

		}
	}
</style>




import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import axios from "../services/axios";

Vue.use(VueRouter);

const routes = [
	{
    	path: '/generator',
		component: () => import('../views/Generator'),
		children: [
			{
				name: 'GeneratorColors',
				title: 'Colors',
				path: 'colors',
				component: () => import('../components/Generators/Colors/Generator'),
				beforeEnter: (to, from, next) => {
					store.dispatch('generator/loadGenerator', to.path).then(res => next())
				}
			},
			{
				name: 'GeneratorLogo',
				title: 'Logo',
				path: 'logo',
				component: () => import('../components/Generators/Logo/Generator'),
				beforeEnter: (to, from, next) => {
					store.dispatch('generator/loadGenerator', to.path).then(res => next())
				},
				meta: {
					// hidden
					//blacklist: ['US']
				}
			},
			{
				name: 'GeneratorCells',
				title: 'Cells',
				path: 'cells',
				component: () => import('../components/Generators/Cells/Generator'),
				beforeEnter: (to, from, next) => {
					store.dispatch('generator/loadGenerator', to.path).then(res => next())
				},
				meta: {
					// hidden
					//blacklist: ['US']
				}
			},
			{
				name: 'GeneratorVibrantM',
				title: 'Vibrant M',
				path: 'vibrantm',
				component: () => import('../components/Generators/VibrantM/Generator'),
				beforeEnter: (to, from, next) => {
					store.dispatch('generator/loadGenerator', to.path).then(res => next())
				}
			},
			{
				name: 'GeneratorString',
				title: 'Strings',
				path: 'string',
				component: () => import('../components/Generators/String/Generator'),
				beforeEnter: (to, from, next) => {
					store.dispatch('generator/loadGenerator', to.path).then(res => next())
				}
			},
			{
				name: 'GeneratorNotFound',
				path: '*',
				meta: {
					status: 404
				}
			}
		]
	},
	{
		path: '/test/:id',
		component: () => import('../components/Tests/Test.vue')
	},
	{
		name: 'NotFound',
		path: '*',
		component: () => import ('../views/NotFound.vue')
	}
];

const router = new VueRouter({
	mode: 'history',
	scrollBehavior: () => ({ y: 0 }),
	routes
});

router.beforeEach((to, from, next) => {

	// redirect to base
	if(to.meta.hasOwnProperty('status') && to.meta.status === 404){
		router.push({ path: '/' });
		return;
	}

	if(to.query.all){
		store.commit('generator/setIsAllInOne', true);
		store.commit('generator/setShowGeneratorIntro', true);
	}

	store.dispatch('generator/clearCustomization');
	store.dispatch('generator/clearGenerator');

	if (store.getters['generator/getIsGeneralConfigLoaded']) {
		next();
	} else {

		const baseEndPoint = store.getters['router/getBaseEndpoint'];
		const trackingSrc = store.getters['router/getTracking'];

		if(baseEndPoint){
			axios.defaults.baseURL = baseEndPoint;
			store.commit('generator/setBaseEndpoint', baseEndPoint);
			store.dispatch('generator/loadGeneralConfig').then(
				() => {
					if(to.query.l && to.query.l.match(/^[a-z]{2}_[A-Z]{2}$/)){
						const segments = to.query.l.split('_', 2);
						store.commit('generator/setContext', segments[1]);
					}

				next();
			}
		)} else {
			console.log('App not configured. No endpoint found.');
		}

		if(trackingSrc){
			let script = document.createElement('script');
			script.setAttribute('src', 'https://' + trackingSrc);
			document.head.appendChild(script);

			store.commit('generator/setIsTracking', true);
		} else {
			store.commit('generator/setIsTracking', false);
		}
	}
});

export default router;

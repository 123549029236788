<template>
    <div class="inputswitch">
        <label v-if="label" class="inputswitch__label">{{label}}</label>

	    <div class="inputswitch__container">
		    <div ref="bg" class="inputswitch__bg" />
		    <div
			    v-for="(item, i) in getFlatItems(value)"
			    :key="i"
			    @click="update(item)"
			    class="inputswitch__item"
			    :class="[selected === item ? 'inputswitch__item--active' : '', beforeSelected === i ? 'inputswitch__item--before' : '']"
		    >{{ getTitle(item) }}</div>
	    </div>
    </div>
</template>

<script>
import InputGeneric from './InputGeneric';
import ResizeObserver from 'resize-observer-polyfill';

export default {
    name: 'InputSwitch',
    extends: InputGeneric,
	props: {
    	label: {
		    type: String,
		    default: ''
	    },
		value: {
			type: Object,
			default: {}
		},
		selected: {
			type: String|Number,
			default: ''
		}
	},

	data() {
        	return {
        		$items: {
        		    type: Object,
		            default: null
				},
				beforeSelected: {
					type: String|Number,
					default: ''
				},
	        }
	},

	watch: {
        selected: function(value){
        	this.setActiveState(value);
		}
	},

    methods: {

	    getFlatItems() {
			return Object.keys(this.value);
	    },

	    update(item) {
	    	this.setActiveState(item);
		    this.save(item);
		    this.$emit('update', item);
		},

	    setActiveState(item){

		    const flattenedItems = this.getFlatItems();
		    const index = flattenedItems.indexOf(item);

	    	if(!this.$items.length){
			    this.$items = this.getItems();
	    	}

	    	if(index >= 0 && this.$items.length && this.$refs['bg']){

				let $selectedItem = this.$items[index];

			    let width = this.selected === this.flatItems.length-1 ? $selectedItem.offsetWidth : $selectedItem.offsetWidth - 3;
			    let left = (this.selected === 1) ? $selectedItem.offsetLeft : $selectedItem.offsetLeft + 1;

			    this.$refs['bg'].style.opacity = 1;
			    this.$refs['bg'].style.width = width + 'px';
				this.$refs['bg'].style.left = left + 'px';
			}

			if(index >= 0 ){
				this.beforeSelected = index-1;
			}

		},

	    getItems(){
		    return this.$el.querySelectorAll('.inputswitch__item');
	    },

	    getTitle(item) {
		    return this.value.hasOwnProperty(item) ? this.value[item] : '';
	    },
    },

	computed: {
    	flatItems(){
    		return this.getFlatItems();
	    }
	},

	mounted: function() {

    	this.$items = this.getItems();

		if(typeof ResizeObserver === 'function') {
			let observer = new ResizeObserver(entries => {
				for(let entry of entries) {
					this.setActiveState(this.selected);
				}
			});
			observer.observe(this.$el.querySelector('.inputswitch__container'));
		}
	}
}
</script>

<style scoped lang="scss">

	.inputswitch {

		$root: &;
		$size: rem-calc(40);
		$border-radius: $size/2;

		display: flex;
		align-items: center;

		font-size: $font-size-small;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		color: $silver;

		&__label {
			@include is-noselect;
			margin-right: $gutter;
			&:empty { display: none; }
		}

		&__container {

			@include is-control;
			position: relative;
			margin: 0 auto;

			display: flex;
			background-color: $light;
			border: 2px solid $light;
			border-radius: $border-radius;

			height: $size;
			overflow: hidden;
		}

		&__item {

			@include is-noselect;

			$item: &;

			flex: 1;

			display: flex;
			align-items: center;
			justify-content: center;

			position: relative;
			z-index: 5;

			height: 100%;
			padding: 0 rem-calc(20);

			cursor: pointer;
			transition: color $speed/2;

			&:not(:last-child):after {
				content: '';
				pointer-events: none;
				touch-action: none;

				position: absolute;
				z-index: -1;
				right: 0;
				width: 0;
				height: 60%;
				border-right: 1px solid rgba($black, .1);
			}

			&--active {
				color: var(--contrast-input-switch, $white);

				&:not(:last-child):after {
					border-right: 1px solid rgba($black, 0);
				}
			}

			&--before {
				&:not(:last-child):after {
					border-right: 1px solid rgba($black, 0);
				}
			}

		}

		&__bg {
			position: absolute;
			z-index: 3;
			width: 10px;
			height: 100%;
			border-radius: $border-radius;
			background-color: $tertiary;

			opacity: 0;
			transition: none $speed-fast;
			transition-property: opacity, left, width;

			pointer-events: none;
			touch-action: none;
		}

	}


</style>

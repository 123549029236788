<template>
	<div class="inputdropdowncolorgroup">
		<div
			v-for="(item, i) in controls"
			:key="i"
			class="control"
		>
			<InputDropDownColorRelation
				:selected="getCustomizationValueByControlId(customizationProperty, Array(itemsLength))[i]"
				:validColors="getValidColors(i)"
				:control="item"
				:handleCustomizationProperty="false"
				:isRequired="isRequired"
				@update="(v) => updateDropDownColor(i, v)"
			/>
		</div>
	</div>
</template>

<script>
	import {mapState, mapActions, mapGetters} from 'vuex';

	import InputGeneric from './InputGeneric.vue';
	import InputDropDownColorRelation from "./InputDropDownColorRelation";

	export default {
		name: "inputDropDownColorRelationGroup",
		extends: InputGeneric,
		props: {
			controls: {
				type: Array,
				default: () => []
			}
		},

		components: {
			InputDropDownColorRelation
		},

		data() {
			return {
				validRelations: undefined,
				validRelationsForPosition: [],
				internalColorsArray: []
			}
		},

		created() {

			// get, set and save default value
			this.internalColorsArray = this.getCustomizationValueByControlId(this.customizationProperty, false);
			if(!this.internalColorsArray) {

				this.internalColorsArray = [];
				for(const control of this.controls){
					if(control.selected){
						this.internalColorsArray.push(control.selected);
					}
				}
				this.addCustomizationProperty({controlId: this.customizationProperty, values: this.internalColorsArray});
			}

			// normalize internalColorsArray
			this.normalizeValue();

			// create positions array
			this.validRelationsForPosition = Array(this.controls.length);
			this.updateValidRelations();
		},

		computed: {
			...mapState('generator', ['generalConfig']),
			...mapGetters('generator', ['getCustomizationValueByControlId', 'getGeneratorConfig']),
			itemsLength: function () {
				return this.controls.length;
			}
		},

		methods: {

			...mapActions('generator', ['addCustomizationProperty']),

			updateDropDownColor(i, value) {
				if(typeof value === 'string') {
					this.internalColorsArray[i] = value;
					this.update();
				}
			},

			updateDropDownColors(colors) {
				if(Array.isArray(colors)) {
					this.internalColorsArray = colors;
					this.update();
				}
			},

			updateValidRelations() {

				// update for positions
				this.validRelationsForPosition = [];

				for(let dropDownIndex = 0; dropDownIndex < this.itemsLength; dropDownIndex++) {

					let allRelations = this.$utility.clone(this.generalConfig.colorRelations);

					// merge with generator color relations
					if(this.getGeneratorConfig.hasOwnProperty('colorRelations') && this.getGeneratorConfig.colorRelations.length){
						allRelations = [...allRelations, ...this.getGeneratorConfig.colorRelations];
					}

					let validRelations = allRelations.filter((relation, relationIndex) => {
						return this.internalColorsArray.every((selectedColor, selectedColorIndex) => {
							return (
								selectedColorIndex === dropDownIndex ||
								!selectedColor ||
								selectedColor === relation[selectedColorIndex]
							);
						});
					});

					// flatten color relations
					this.validRelationsForPosition[dropDownIndex] = validRelations.map(function(value) { return value[dropDownIndex]; });
					// make unique
					this.validRelationsForPosition[dropDownIndex] = this.validRelationsForPosition[dropDownIndex].filter( function (value, index, self) { return self.indexOf(value) === index; } )
					// sort
					this.validRelationsForPosition[dropDownIndex].sort();

				}
			},

			getValidColors(i) {
				return this.validRelationsForPosition[i]
			},

			normalizeValue() {
				// fill array
				for(let i = 0; i < this.itemsLength; i++) {
					if(!this.internalColorsArray[i])
						this.internalColorsArray[i] = '';
				}

				// but array
				this.internalColorsArray = this.internalColorsArray.slice(0, this.itemsLength);
			},

			update(){
				this.updateValidRelations();
				this.normalizeValue();
				this.save(this.internalColorsArray);
				this.$emit('update', this.internalColorsArray);
			}
		}
	};
</script>

<style scoped lang="scss">

	.inputdropdowncolorgroup {

		display: contents;
		@include flex-fallback;

		.control {
			flex: 1!important;
		}

	}

</style>

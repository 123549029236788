<template>
	<div class="inputlayoutsize">
		<DropDown
			:subtitle="control.subtitle"
			:items="control.values"
			:selected="selected"
		>
			<template #button="{item}">
				{{ getItemTitle(item) }}
			</template>
			<template #list="{item}">
				<div class="dropdown__item" @click="selectSize(item)">
					{{ getItemTitle(item) }}
				</div>
			</template>
		</DropDown>

		<InputNumberDropDown
			:label="getL10nFromConfig('layoutWidth')"
			:value="{ value: format.width, unit: format.unit }"
			:min="getMinimum()"
			:max="getMaximum()"
			:numberType="getNumberType()"
			:numberStep="getNumberStep()"
			@update="updateLayoutSizeWidth"
			@checkMinMax="checkFormat"
		/>

		<button
			class="control button button--secondary button--round icon icon--swap"
			aria-label="Swap sizes"
			@click="switchWidthHeight"
		/>

		<InputNumberDropDown
			:label="getL10nFromConfig('layoutHeight')"
			:value="{ value: format.height, unit: format.unit }"
			:min="getMinimum()"
			:max="getMaximum()"
			:numberType="getNumberType()"
			:numberStep="getNumberStep()"
			@update="updateLayoutSizeHeight"
			@checkMinMax="checkFormat"
		/>
	</div>
</template>

<script>
	import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';

	import InputGeneric from './InputGeneric';
	import DropDown from '../General/DropDown';
	import InputNumberDropDown from './InputNumberDropDown';

	export default {
		name: 'InputLayoutSize',
		extends: InputGeneric,

		components: {
			DropDown,
			InputNumberDropDown
		},

		data() {
			return {
				format: {
					title: '',
					width: 0,
					height: 0,
					unit: '',
					orientation: ''
				},
				// stores previously selected format
				previousFormat: null,
				selectedItem: null,
				stepMultiplicator: 1
			}
		},

		props: {
			selected: {
				type: String
			}
		},

		computed: {
			...mapGetters('generator', ['getL10nFromConfig', 'getFormatFromConfig', 'getFormatRangeByUnit', 'getCustomizationValueByControlId']),
			...mapState ('generator', ['generalConfig', 'isValidFormat'])
		},

		methods: {
			...mapActions('generator', ['addCustomizationProperty']),
			...mapMutations('generator', ['setIsValidFormat']),
			...mapMutations('notification', ['clearMessages', 'addMessage']),

			getMinimum(){
				if(typeof this.format !== 'undefined') {
					return this.getFormatRangeByUnit('min', this.format.unit);
				}
			},

			getMaximum(){
				if(typeof this.format !== 'undefined') {
					return this.getFormatRangeByUnit('max', this.format.unit);
				}
			},

			getNumberType(){
				return this.format.unit === 'in' ? 'float' : 'int';
			},

			getNumberStep(){
				return (this.format.unit === 'in' ? .1 : 1) * this.stepMultiplicator;
			},

			selectSize(format) {
				if(typeof format !== 'string') console.error('format must be a string');
				this.selectedItem = format;

				let newFormat = this.getFormatFromConfig(format);
				if(format === 'custom') {
					newFormat.width = this.format.width * 1;
					newFormat.height = this.format.height * 1;
					newFormat.unit = this.format.unit;
				}

				this.format = newFormat;
				this.previousFormat = {...this.format};

				this.update();
			},

			getItemTitle(item) {
				return this.getFormatFromConfig(item).title;
			},

			updateLayoutSizeWidth(value) {
				this.previousFormat = {...this.format};
				this.format.width = value.value;
				this.format.unit = value.unit;
				this.setPresetOrUpdate();
			},

			updateLayoutSizeHeight(value) {
				this.previousFormat = {...this.format};
				this.format.height = value.value;
				this.format.unit = value.unit;
				this.setPresetOrUpdate();
			},

			switchWidthHeight() {
				[this.format.width, this.format.height] = [this.format.height, this.format.width];
				this.setPresetOrUpdate();
			},

			setPresetOrUpdate() {
				const format = this.isPresetFormat();
				if(format) {
					this.selectSize(format);
				} else {
					this.selectedItem = 'custom';

					if(this.previousFormat.unit !== this.format.unit) {
						const defaultValue = this.getFormatRangeByUnit('default', this.format.unit);
						this.format.width = defaultValue[0];
						this.format.height = defaultValue[1];
					}

					this.update();
				}
			},

			/**
			 * Check if the current values (width, height, unit) are contained in the presets list
			 * @returns {string|boolean}
			 */
			isPresetFormat(){

				if(this.generalConfig.formats) {
					for(let item in this.generalConfig.formats) {
						if(
							!this.generalConfig.formats.hasOwnProperty(item) ||
							item === 'custom'
						) continue;
						const f = this.generalConfig.formats[item];

						if(
							f.width === this.format.width &&
							f.height === this.format.height &&
							f.unit === this.format.unit &&
							this.control.values.indexOf(item) >= 0
						) {
							return item;
						}
					}
				}
				return false;
			},

			update() {

				const values = [
					this.selectedItem,
					this.format.width,
					this.format.height,
					this.format.unit
				];

				this.save(values);
				this.$emit('update', { control: this.control, selected: values });
			},

			checkFormat(){
				if(
					this.format.width >= this.getMinimum() &&
					this.format.height >= this.getMinimum() &&
					this.format.width <= this.getMaximum() &&
					this.format.height <= this.getMaximum()
				) {
					this.setIsValidFormat(true);
				} else {
					this.setIsValidFormat(false);
				}
			},

			handleKey(e){
				this.stepMultiplicator = e.shiftKey ? 10 : 1;
			}
		},

		mounted() {

			document.addEventListener('keydown', this.handleKey);
			document.addEventListener('keyup', this.handleKey);

			const controlId = this.getControlId();

			if(this.selected === 'custom' && controlId){
				const customization = this.getCustomizationValueByControlId(controlId);

				if(customization){
					this.format.width = customization[1];
					this.format.height = customization[2];
					this.format.unit = customization[3];
				}
			}

			this.selectSize(this.selected);
		},

		beforeDestroy(){
			document.removeEventListener('keydown', this.handleKey);
			document.removeEventListener('keyup', this.handleKey);
		}
	}
</script>

<style scoped lang="scss">
	.inputlayoutsize {

		display: contents;

		@include flex-fallback;

		> * {
			flex: 1;
			&:first-child {
				width: 30%;
				flex: none;
				@include __ {
					.dropdown__list { min-width: calc(100% - 2px); }
				}
			}
			&:not(:last-child) {
				margin-right: $gutter;
			}
		}

	}

</style>

<script>
/**
 * code inspired by https://www.npmjs.com/package/vue-simple-svg
 */
export default {
	render (createElement) {
		return createElement('svg')
	},
	name: 'SvgLoader',
	props: {
		src: {
			type: String,
			required: true
		},
		customId: {
			type: String,
			default: ''
		},
		customClassName: {
			type: String,
			default: ''
		}
	},
	mounted () {
		this.generateInlineSVG();
	},
	watch: {
		src (val) {
			// Re-generate inline SVG if src is updated
			this.generateInlineSVG();
		}
	},
	methods: {
		getScopeId(){
			return this.$parent.$options._scopeId;
		},

		/* Load an SVG file with XHR and generate an inline SVG code */
		generateInlineSVG () {

			//if(this.src === null) return;

			const context = this;

			// Reset first. Remove all the code of the existing inline SVG
			this.resetInlineSVG();

			// Get the content of the SVG file
			const request = new XMLHttpRequest();
			request.open('GET', this.src, true);

			request.onload = () => {
				if (request.status >= 200 && request.status < 400) {
					// Setup a DOM parser to convert the response to text/xml
					const domParser = new DOMParser();
					const result = domParser.parseFromString(request.responseText, 'text/xml');
					const loadedSVG = result.getElementsByTagName('svg')[0];

					if (!loadedSVG) {
						context.$emit('fail');
						console.error('No SVG element found in the given file: ' + context.filepath);
						return;
					}

					// add attributes to the inline SVG
					const attributeNames = loadedSVG.getAttributeNames();
					for (const name of attributeNames) {
						const value = loadedSVG.getAttribute(name);
						context.$el.setAttribute(name, value);
					}
					if (context.customId) context.$el.id = context.customId;
					if (context.customClassName) context.$el.setAttribute('class', context.customClassName);

					// hide svg
					context.$el.style.visibility = 'hidden';

					// add child nodes to the inline SVG
					const domN = loadedSVG.children.length;

					for (let i = 0; i < domN; i++) {
						const node = loadedSVG.children.item(i);
						context.$el.appendChild(node.cloneNode(true));
					}

					// now the inline SVG is generated
					context.$emit('load');
				}

				if(this.getScopeId()){
					this.$el.setAttribute(this.getScopeId(), true);
				}

			}

			request.onerror = function () {
				console.error('There was on XML Http Request');
			}

			request.send();
		},

		resetInlineSVG () {
			while (this.$el.firstChild) {
				this.$el.removeChild(this.$el.firstChild);
			}
			const attributeNames = this.$el.getAttributeNames();
			for (const name of attributeNames) {
				this.$el.removeAttribute(name);
			}
		}
	}
};
</script>

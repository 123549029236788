<template>

	<div class="tooltip" :class="{ 'tooltip--open': isOpen === true  }">
		<div class="tooltip__button" :class="{ 'tooltip__button--inverted': isInitial === false }" @mouseover="hoverIn" @mouseout="hoverOut"></div>
		<div class="tooltip__box" :class="'tooltip__box--' + position" ref="box">
			<div class="tooltip__header"><slot name="tooltip-title"></slot></div>
			<div class="tooltip__content"><slot name="tooltip-text"></slot></div>
		</div>
	</div>

</template>

<script>
	export default {
	  	name: "Tooltip",
		data() {
			return {
				isOpen: false,
				isInitial: true,
				position: 'right'
			}
		},
	  	methods: {
			hoverIn() {
				this.updatePosition();
				this.isOpen = true;
			},
			hoverOut() {
				this.isOpen = false;
			},
		    updatePosition(){
				if(this.$refs?.box) {
					const clone = this.$refs.box.cloneNode(true);
					clone.classList.replace('tooltip__box--left', 'tooltip__box--right');
					clone.style.display = 'block';

					const $node = this.$el.appendChild(clone);
					const boundingBox = $node.getBoundingClientRect();
					$node.remove();

					this.position = (window.innerWidth - boundingBox.x) < boundingBox.width ? 'left' : 'right';
				}
		    }
	  	}
	};
</script>

<style scoped lang="scss">

	.tooltip {

		$root: &;
		@mixin open { @at-root #{$root}--open & { @content } }
		@mixin hover { @at-root #{$root}:hover & { @content } }

		position: relative;
		z-index: 3;
		display: inline-block;

		label & {
			top: 1px;
		}

		&__button {

			position: relative;
			z-index: 3;

			color: $silver;
			cursor: pointer;

			@include hover {
				color: $tertiary;
				border-color: $tertiary;
			}

			@include open {
				color: $tertiary;
				border-color: $tertiary;
			}

			@include icon('tooltip', ':after') {

				pointer-events: none;
				touch-action: none;
				color: $silver;

				transition: transform $speed-fast;

				@include hover {
					color: $tertiary;
				}

			}
		}

		&__box {

			position: absolute;
			left: calc(-#{$half-gutter}/2 - 1px);
			top: $gutter;
			width: rem-calc(250);
			padding: $half-gutter;

			border-radius: $border-radius;
			background-color: $white;
			border: 1px solid $light;

			font-size: $font-size-small;
			line-height: $line-height-normal;
			font-weight: $font-weight-normal;
			text-transform: none;
			letter-spacing: 0;
			color: $black;
			white-space: normal;

			display: none;

			@include open {
				display: block;
			}

			&:before, &:after {
				content: '';
				position: absolute;
				bottom: 100%;
				width: 0;
				height: 0;
			}

			$size: rem-calc(10px);

			&:before {
				left: 5px;
				border-bottom: $size solid $light;
				border-right: $size solid transparent;
				border-left: $size solid transparent;
			}

			&:after {
				left: rem-calc(7px);
				border-bottom: calc(#{$size} - 2px) solid $white;
				border-right: calc(#{$size} - 2px) solid transparent;
				border-left: calc(#{$size} - 2px) solid transparent;
			}

			&--left {
				left: auto;
				right: -$half-gutter;

				&:before, &:after {
			        left: auto;
				}
				&:before {
			        right: rem-calc(14px);
				}
				&:after {
					right: rem-calc(16px);
				}
			}

		}

		&__header {
			font-weight: $font-weight-bold;
		}

		&__content {
			padding: #{$half-gutter/2} 0 0 0;
		}

	}

</style>

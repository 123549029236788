<template>
	<div class="inputdropdown" v-if="control">
		<DropDown
			:subtitle="control.title"
			:items="getFlatItems(control.values)"
			class="dropdown--large"
			:class="{ 'dropdown--icon': hasIcon === true }"
			:selected="selected"
			>
			<template #button="{item}">
				<span>{{ getTitle(item) }}</span>
			</template>

			<template #button-prepend="{item}">
				<img
					v-if="getIcon(item)"
					:src="getIcon(item)"
					:alt="'Icon ' + getTitle(item)"
				/>
			</template>

			<template #list="{item}">
				<div class="dropdown__item" @click="update(item)">
					<img
						v-if="getIcon(item)"
						:src="getIcon(item)"
						:alt="'Icon' + getTitle(item)"
					/>
					{{ getTitle(item) }}
				</div>
			</template>
		</DropDown>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	import InputGeneric from './InputGeneric.vue';
	import DropDown from '../General/DropDown.vue';
	import SvgLoader from "../General/SvgLoader";

	export default {
		name: "InputDropDown",
		extends: InputGeneric,
		components: {
			DropDown, SvgLoader
		},
		props: {
			selected: String|Number
		},
		data () {
			return {
				hasIcon: false
			}
		},
		methods: {

			getFlatItems() {
				return this.control.values.map((i) => { return i.value });
			},

			getTitle(item) {
				const completeObj = this.control.values.filter (val => val.value === item);
				if(completeObj) return completeObj[0].title;
				else return null;
			},

			getIcon(item) {
				const completeObj = this.control.values.filter (val => val.value === item);
				if(completeObj && completeObj[0].icon) {
					this.hasIcon = true;
					return this.baseEndpoint + '/' + this.generator.config.assets[completeObj[0].icon];
				} else return null;
			},

			update(item) {
				this.save(item);
				this.$emit('update', { control: this.control, selected: item });
			}

		},
		computed: {
			...mapState('generator', [ 'customization', 'generator', 'baseEndpoint' ]),
		}
	};
</script>

<style lang="scss" scoped>
	.inputdropdown { max-width: rem-calc(320) }
</style>

<template>
	<div id="app">
		<router-view/>
		<div class="notification notification--warning">
			<h3 class="notification__title" v-if="getL10nFromConfig('invalidViewportTitle')">
				{{ getL10nFromConfig('invalidViewportTitle') }}
			</h3>
			<div class="notification__body" v-if="getL10nFromConfig('invalidViewportDescription')">
				<p>{{ getL10nFromConfig('invalidViewportDescription') }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';

	export default {
		name: "App",

		computed: {
			...mapState('generator', ['generator','generalConfig', 'isShowingExportManager', 'isShowingModal', 'isTracking', 'trackedGenerators']),
			...mapGetters ('generator', ['getL10nFromConfig','getGeneratorConfig']),

		},

		watch: {
			'generalConfig': function() { this.setLanguage(); },
			'isShowingModal': function(v) { this.toggleScrolling(v) },
			'isShowingExportManager': function(v) { this.toggleScrolling(v) }
		},

		methods: {

			setLanguage(){

				// document
				const iso2 = this.getL10nFromConfig('iso2');
				if(iso2) document.documentElement.setAttribute('lang', iso2);

				// moment.js
				const locale = this.getL10nFromConfig('feLocale');
				if(locale) this.$moment.locale(locale);

			},

			toggleScrolling(state) {
				const scrollingEl = document.scrollingElement;
				if(state) {
					const scrollTop = scrollingEl.scrollTop;
					scrollingEl.style.overflow = 'hidden';
					scrollingEl.scrollTop = scrollTop;
				} else {
					scrollingEl.style.overflow = 'auto';
				}
			}

		},

		mounted: function(){

			//Tracking: Interactions per Generator
			let rootElement = document.querySelector('#app');
			let _this = this;

			rootElement.addEventListener('click', RespondClick); 
			function RespondClick(event){
				let targetElement = event.target;
				if( 
					(	
						targetElement.classList.contains('button')
						|| targetElement.classList.contains('button__text')
						|| targetElement.classList.contains('dropdown__item')
						|| targetElement.classList.contains('card_bg')
						|| targetElement.classList.contains('inputswitch__item')
						|| targetElement.classList.contains('pagination__item__link')
						|| targetElement.classList.contains('inputnumber__number')
						|| targetElement.classList.contains('inputcolor__item')
						|| targetElement.classList.contains('tooltip__button')
						|| targetElement.classList.contains('inputdropdownbackgrondcolor__selected')
						|| targetElement.classList.contains('slider__dot')
						|| targetElement.id === 'dragarea'
						|| targetElement.matches('h3')
						|| targetElement.matches('i')
						|| targetElement.matches('img')
					)
					&&
					(
					 	!_this.trackedGenerators.includes(_this.generator.config.name)
					)
				){
					if(_this.isTracking){
						_satellite.track("GeneratorInteraction",{"generatorName":_this.generator.config.name});
					} else {
						console.log('Tracking Demo: GeneratorInteraction', _this.generator.config.name); 
					}
					_this.trackedGenerators.push(_this.generator.config.name);
				} 
			}
			
		}

	}
</script>

<style lang="scss">

	@import '~/assets/styles/app.scss';

	#app {
		//@include showBreakpoints;
		.generator__wrapper {
			display: none;
			@include sidebar {
				display: block;
			}
		}

		& > .notification {
			display: block;
			margin: $gutter;
			min-width: 0;	
			@include sidebar {
				display: none;
			}
		}
	}

</style>

<template>
	<div class="notification notification--warning">
		<h3 class="notification__title" v-if="title">
			{{title}}
		</h3>
		<div class="notification__body" v-if="messages">
			<p v-for="(v,i) in messages">{{messages[i]}}</p>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';

	export default {
		name: "Notification",
		props: ['title'],

		computed: {
			...mapState('notification', ['messages'])
		}
	}

</script>

<template>
	<div class="inputdropdowncolor">

		<DropDown
			:subtitle="control.subtitle"
			:items="control.values"
			class="dropdown--large"
			:class="{'dropdown--required': isRequired, 'dropdown--alert': selected === ''}"
			v-if="control"
			:highlight=false
		>

			<!-- Title -->
			<template #button="{item}">
				{{ selectedTitle }}
			</template>

			<!-- Circle -->
			<template #button-prepend="{item}">
				<span
					class="inputdropdowncolor__selected"
					:class="additionalCssClassesSelected()"
					:style="'background-color: ' + selectedColor"
				/>
			</template>

			<!-- Items -->
			<template #list="{item}">
				<div class="dropdown__item inputdropdowncolor__item">
					<label class="label">{{ item.title }}</label>
					<div class="inputdropdowncolor__group">
						<div
							v-for="color in item.values"
							class="inputdropdowncolor__group__item"
							:class="additionalCssClassesItem(color)"
							:style="'background-color: ' + getHex(color)"
							@click="selectItem(color)"
						 />
					</div>
				</div>
			</template>

		</DropDown>

	</div>
</template>

<script>
	import {mapState, mapActions, mapGetters} from 'vuex';

	import InputGeneric from './InputGeneric.vue';
	import DropDown from '../General/DropDown.vue';

	export default {
		name: "InputDropDownColorRelation",
		extends: InputGeneric,
		props: {
			validColors: {
				type: Array,
				default: () => []
			},
			selected: {
				type: String,
				default: ''
			}
		},

		components: {
			DropDown
		},

		computed: {

			...mapState ('generator', ['generalConfig']),
			...mapGetters ('generator', ['getColorFromConfig', 'getL10nFromConfig']),

			selectedColor: function () {
				return this.getHex(this.selected)
			},

			selectedTitle: function () {
				if(!this.selected) return this.control.title;
				const color = this.getColorFromConfig(this.selected);
				return color ? color.name : this.control.title;
			}
		},

		methods: {
			...mapActions ('generator', ['addCustomizationProperty']),

			getHex: function (colorKey) {
				const color = this.getColorFromConfig(colorKey);
				return color && color.hasOwnProperty('values') ? color.values.hex[0] : 'transparent';
			},

			additionalCssClassesSelected(color) {
				let result = [];
				if(this.selected){
					result.push('inputdropdowncolor__selected--fill');
					result.push('inputdropdowncolor__selected--' + this.selected);
				}
				return result.join(' ');

			},
			additionalCssClassesItem(color) {

				let result = [];
				// active
				if(this.selected === color){
					result.push('inputdropdowncolor__group__item--active');
				}

				if(this.generalConfig.colors.hasOwnProperty(color)){
					result.push(`inputdropdowncolor__group__item--${color}`);
					if(this.validColors.length > 0 && !this.validColors.includes(color)) {
						result.push('inputdropdowncolor__group__item--locked');
					}
				}
				return result.join(' ');
			},

			selectItem (color) {
				if(this.selected === color) {
					this.removeColor(color);
				} else if(
					typeof color !== 'undefined' &&
					this.validColors.length > 0 ? this.validColors.includes(color) : true
				) {
					this.update(color);
				}
			},

			update(value) {
				this.save(value);
				this.$emit('update', value);
			},

			removeColor () {
				this.update('');
			}
		}
	};
</script>

<style scoped lang="scss">

	$colorSelectionSize: rem-calc(50);

	.inputdropdowncolor {

		display: contents;

		&__selected {
			position: absolute;
			left: $half-gutter;
			display: block;
			border-radius: 50%;
			border: 1px dashed $silver;

			width: $colorSelectionSize;
			height: $colorSelectionSize;

			&--fill {
				border-color: transparent;
			}

			&--white {
				border: 1px solid $silver;
			}
		}

		&__item {

			height: rem-calc(110) !important;
			cursor: auto !important;

			@include hover {
				background-color: inherit !important;
			}

			label {
				margin-bottom: $half-gutter/2 !important;
			}

		}

		&__group {

			$padding: $half-gutter/2;

			display: flex;
			margin: 0 -#{$padding};

			&__item {
				display: block;
				flex: 1;

				border-radius: 50%;
				margin: 0 $padding;

				cursor: pointer;

				max-width: rem-calc(35);
				width: 100%;
				span {
					display: block;
					content: '';
				}

				&:before {
					@include icon-setting;
					display: block!important;
					line-height: 0!important;
					height: 0;
					padding: 50% 0;
					color: $white;
					content: '';
					text-align: center;
				}

				@include hover-or-active {
					@include icon-content('plus', ':before') {
						transform: translate(.5px, -.5px);
					}
				}

				&--active {
					@include icon-content('check', ':before');
					@include hover-or-active {
						@include icon-content('minus', ':before');
					}
				}

				&--locked {
					pointer-events: none;
					touch-action: none;
					@include icon-content('lock', ':before') {
						transform: translate(0, -1px);
					}
				}

				&--white {
					border: 1px solid $silver;
					&:before {
						color: $black;
					}
				}
			}
		}

		/*
		&__footer {
			label {
				@include icon('delete', ':after', 'small') {
					margin-left: $half-gutter/2;
				}
			}
		}
		*/

		&__export-extras {

			label {

			}
			input {

			}
			span {
				font-size: $font-size-smaller;
				line-height: 1.4;
				font-weight: normal;
			}

		}

		@include __ {

			.dropdown {
				$dropdown: &;

				&__item {
					&--selected {
						padding-left: $colorSelectionSize + $half-gutter*2;
					}

				}
			}

		}

	}

</style>

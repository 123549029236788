<template>
	<div class="inputnumberdropdown inputnumber" v-if="generalConfig.units">

		<input
			:id="inputAnchor"
			class="inputnumber__number"
			type="number"
			:value="value.value"
			:numberType="numberType"
			:step="numberStep"
			min="0"
			@change="updateInputNumber($event.target.value*1)"
		/>

		<DropDown
			:items="getFlatItems()"
			class="dropdown--small"
			:selected="value.unit"
		>
			<template #button="{item}"> {{ item }} </template>

			<template #list="{item}">
				<div class="dropdown__item" @click="updateInputNumberDropDown(item)">
					{{ getUnitTitle(item) }}
				</div>
			</template>
		</DropDown>

		<Tooltip>
			<template v-slot:tooltip-title>
				{{ getL10nFromConfig('unitSwitchTitle') }}
			</template>
			<template v-slot:tooltip-text>
				{{ getL10nFromConfig('unitSwitchDescription') }}
			</template>
		</Tooltip>

		<label :for="inputAnchor" class="label label--light">{{label}}</label>
	</div>
</template>

<script>
	import InputNumber from './InputNumber.vue';
	import DropDown from '../General/DropDown.vue';
	import Tooltip from '../General/Tooltip.vue';
	import {mapActions, mapGetters, mapState, mapMutations} from 'vuex';

	export default {
		name: 'InputNumberDropDown',
		extends: InputNumber,
		components: {
			DropDown,
			Tooltip
		},

		props: {
			value: {
				type: Object,
				default: {
					value: 0,
					unit: ''
				}
			}
		},

		data() {
			return {
				inputNumber: '',
				inputNumberDropDown: '',
				isValid: true
			};
		},

		watch: {
			value: function(value){
				this.inputNumber = this.formatNumber(value.value);

				this.$emit('checkMinMax');

				if(value.unit !== ''){
					this.inputNumberDropDown = value.unit;
				}
			}
		},

		methods: {
			...mapActions('generator', ['addCustomizationProperty']),

			updateInputNumberDropDown(item) {
				if (typeof item !== 'undefined') {
					this.inputNumberDropDown = item;
					this.update();
				}
			},

			getFlatItems() {
				return Object.keys( this.generalConfig.units );
			},

			getUnitTitle(value) {
				return this.generalConfig.units[value].title;
			},

			update() {

				const values = {
					value: this.formatNumber(this.inputNumber),
					unit: this.inputNumberDropDown
				};

				this.save(values);
				this.$emit('update', values);
			}
		},

		computed: {
			...mapState ('generator', ['generalConfig']),
			...mapGetters('generator', ['getL10nFromConfig']),
			selectedDropDown: function(){
				return this.inputNumberDropDown;
			},
			inputAnchor: function() {
				return 'INDD_' + this.$utility.randomString();
			}
		}

	}
</script>

<style scoped lang="scss">

	.inputnumberdropdown {
		@include __ {
			.dropdown, .tooltip {
				position: absolute;
				z-index: 10;
				transform: translateY(rem-calc(-12));
			}
			.dropdown {
				right: $gutter;
			}
			.tooltip {
				width: $half-gutter;
				right: 0;
			}
		}
	}

</style>

<script>


import { mapActions } from 'vuex';

export default {
    name: 'InputGeneric',
	props: {
		control: {},
		selectionState: {},
		label: {
			type: String,
			default: ''
		},
		value: {
			type: String,
			default: ''
		},
		customizationProperty: {
			type: String,
			default: null
		},
		handleCustomizationProperty: {
			type: Boolean,
			default: false
		},
		isRequired: {
			type: Boolean,
			default: false
		}
	},
	methods: {

		...mapActions ('generator', ['addCustomizationProperty']),

    	save(item){

    		if(typeof item !== 'undefined'){

				const controlId = this.getControlId();

				if(controlId){
				    item = Array.isArray(item) ? item : [item];

				    if(this.handleCustomizationProperty){
					    this.addCustomizationProperty ({
						    controlId: controlId,
						    values: item
					    });
				    }

				}

		    }
	    },

		getControlId(){
			let controlId = null;

			if(typeof this.control !== 'undefined' && this.control.hasOwnProperty('id')){
				controlId = this.control.id;
			} else if(this.customizationProperty){
				controlId = this.customizationProperty;
			}
			return controlId;

		}

	}
}
</script>

<template>
	<div class="modal__wrapper" @click="clickOutside">
        <div class="modal" @click="clickModal" :class="additionalClass">
	        <div class="modal__close">
		        <button
			        class="button button--transparent modal__close__button"
			        @click="close">
		        </button>
	        </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
    name: "Modal",
	props: {
		additionalClass: {
			type: String,
			default: ''
		}
	},
    methods: {
        close(e) {
	        e.stopPropagation();
        	this.$emit('close');
        },
	    clickOutside(e) {
		    e.stopPropagation();
        	this.$emit('clickOutside');
        },
	    clickModal(e) {
        	e.stopPropagation();
        	this.$emit('clickModal'); }
    }
};
</script>

<style lang="scss">

	.modal__wrapper {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 20;
	}

	.modal {
		background: white;
		position: relative;
		padding: $gutter;
		max-width: 42rem;
		min-width: 30rem;
		display: flex;
		flex-direction: column;
		max-height: 90vh;
		overflow-y: auto;

		&--expand {
			flex-grow: 1;
		}

		&__close {
			font-size: 1rem;
			cursor: pointer;
			position: absolute;
			top: $gutter;
			right: $gutter;

			transform: translateY(rem-calc(-8)); /* half of icon height */

			&__button {
				@include icon('close', ':after');
			}
		}

		&__header, &__content {
			margin-bottom: $gutter;
		}

		&__header {
			max-width: calc(100% - #{$gutter}*2);
			font-weight: $font-weight-bold;

			&--bigger {
				font-size: $font-size-bigger;
			}
		}

		&__content {

			h2 {
				font-weight: $font-weight-bold;
				margin-bottom: $half-gutter;
			}

			h3 {
				font-weight: $font-weight-bold;
				margin-bottom: $half-gutter;
			}

			p {
				margin-bottom: $half-gutter;

				a {
					color: $tertiary;
				}
			}

			&--text {
				font-size: $font-size-small;
				font-weight: $font-weight-normal;
				line-height: $line-height-large;
			}
		}

		&__list {
			margin-bottom: $gutter;

			&__title {
				margin-bottom: 0!important;
				color: $primary;
			}

			&--download {
				position: relative;

				li {
					margin-left: rem-calc(24);
					@include icon('check',':before'){
						position: absolute;
						left: 0;
						color: $primary;
					};
				}
			}
		}

		&__figure {
			img {
				max-width: 100%;
			}
		}

		&__footer {

		}
	}

</style>

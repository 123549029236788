<template>
    <header class="header">
	    <div class="container">
		    <div class="header__content" v-if="isAllInOne">
				<h1 class="header__title">
				    {{ getL10nFromConfig('allInOneTitle') }}
			    </h1>
			    <div class="header__description">
				    <p>{{ getL10nFromConfig('allInOneDescription') }}</p>
				    <p><strong>{{ getL10nFromConfig('allInOneHighlight') }}</strong></p>
			    </div>
		    </div>
			<div class="header__content" v-else>
			     <h1 class="header__title">
				    {{ title }}
			    </h1>
			    <div class="header__description">
				    <p>{{ description }}</p>
				    <p><strong>{{ highlight }}</strong></p>
			    </div>
		    </div>
	    </div>
    </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
    name: 'Header',
	props: ['title', 'description', 'highlight'],
	computed: {
		...mapState ('generator', ['isAllInOne']),
		...mapGetters ('generator', ['getL10nFromConfig'])
	},
};
</script>

<style scoped lang="scss">

	.header {

		background-color: $primary;
		padding: $gutter-bigger 0  $gutter-bigger + $navbar-height 0;

		&__content {
			display: flex;
			justify-content: space-between;

			color: $white;
			line-height: $line-height;
		}

		&__title, &__description {
			margin: 0 $gutter;
		}

		&__title {
			flex: 6;
			font-family: $font-special;
			color: $secondary;
			text-transform: uppercase;
			font-size: rem-calc(28);
		}

		&__description {
			flex: 10;
			margin-left: 0;
			line-height: $line-height-large;

			p + p {
				margin-top: $half-gutter/2;
			}
		}

	}

</style>

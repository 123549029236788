<template>
	<div class="inputnumber">
		<input
			:id="inputAnchor"
			class="inputnumber__number"
			type="number"
			:value="value"
			min="0"
			:step="numberStep"
			:numberType="numberType"
			@change="updateInputNumber($event.target.value*1)" />
		<label :for="inputAnchor" class="label label--light">{{label}}</label>
	</div>
</template>

<script>
	import InputGeneric from './InputGeneric';
	import {mapActions} from 'vuex';

	export default {
		name: 'InputNumber',
		extends: InputGeneric,
		props: {
			value: {
				type: Number
			},
			min: {
				type: Number,
				default: 0
			},
			max: {
				type: Number,
				default: Number.MAX_SAFE_INTEGER
			},
			numberType: {
				type: String,
				default: 'int'
			},
			numberStep: {
				type: Number,
				default: 1
			}
		},

		data() {
			return {
				inputNumber: 0
			};
		},

		computed: {
			inputAnchor: function() {
				return 'IN_' + this.$utility.randomString();
			}
		},

		methods: {
			...mapActions('generator', ['addCustomizationProperty']),

			updateInputNumber(number) {
				this.inputNumber = this.formatNumber(number);
				this.update();
			},

			update() {
				this.save(this.inputNumber);
				this.$emit('update', this.inputNumber);
			},

			formatNumber(number){
				const n = this.numberType === 'int' ? parseInt(number) : parseFloat(parseFloat(number).toFixed(1));
				return n;
			}
		}

	}
</script>

<style lang="scss">

	.inputnumber {

		@include is-input;
		@include is-noselect;
		position: relative;

		&__number {
			border: none;
			border-bottom: 1px solid $black;
			padding: .2rem 0;
			width: 100%;

			font-family: inherit;
			font-size: $font-size-bigger;
			font-weight: $font-weight-normal;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			/* Firefox */
			&[type=number] {
				-moz-appearance: textfield;
			}

			&:focus {
				outline: none;
				border-bottom-color: $tertiary;
			}
		}

		label {
			margin-top: rem-calc(5);
		}
	}
</style>

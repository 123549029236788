<template>
	<div class="inputdropdownbackgrondcolor controls">
		<DropDown
			:subtitle="''"
			:items="items || control.values"
			class="dropdown--compact"
			:highlight=false
		>

			<!-- Title -->
			<template #button="{item}">{{getL10nFromConfig('previewBackground')}}</template>

			<!-- Circle -->
			<template #button-prepend="{item}">
				<span
					class="inputdropdownbackgrondcolor__selected"
					:class="{'inputdropdownbackgrondcolor__selected--fill': selected}"
					:style="'background-color: ' + selectedColor"
				/>
			</template>

			<template #list-prepended>
				<li class="dropdown__item dropdown__item--header inputdropdownbackgrondcolor__header">
					<label class="label">{{getL10nFromConfig('previewBackground')}}</label>
				</li>
			</template>

			<!-- Items -->
			<template #list="{item}">
				<div class="dropdown__item inputdropdownbackgrondcolor__item">
					<label>{{ item.title }}</label>
					<div class="inputdropdownbackgrondcolor__group">
						<div
							v-for="color in item.values"
							class="inputdropdownbackgrondcolor__group__item"
							:class="additionalCssClasses(color)"
							:style="'background-color: ' + getHex(color)"
							@click="selectItem(color)"
						 />
					</div>
				</div>
			</template>

		</DropDown>

	</div>
</template>

<script>
	import {mapState, mapActions, mapGetters} from 'vuex';

	import InputGeneric from './InputGeneric.vue';
	import DropDown from '../General/DropDown.vue';

	export default {
		name: "InputDropDownBackgroundColor",
		extends: InputGeneric,
		props: {
			items: Array,
			validColors: {
				type: Array,
				default: () => []
			},
			selected: {
				type: String,
				default: ''
			},
			defaultValue: {
				type: String,
				default: ''
			}
		},

		components: {
			DropDown
		},

		created() {
			// default value
			if(this.defaultValue && !this.selected)
				this.selectItem(this.defaultValue);
		},

		computed: {

			...mapState ('generator', ['generalConfig']),
			...mapGetters ('generator', ['getColorFromConfig', 'getL10nFromConfig']),

			selectedColor: function () {
				return this.getHex(this.selected)
			},

			selectedTitle: function () {
				if(!this.selected) {
					return this.control ? this.control.title : '';
				}
				const color = this.getColorFromConfig(this.selected);
				return color ? color.name : this.control.title;
			}
		},

		methods: {
			...mapActions ('generator', ['addCustomizationProperty']),

			getHex: function (colorKey) {
				const color = this.getColorFromConfig(colorKey);
				return color && color.hasOwnProperty('values') ? color.values.hex[0] : 'transparent';
			},

			additionalCssClasses(color) {

				let result = [];

				// active
				if(this.selected === color)
					result.push('inputdropdownbackgrondcolor__group__item--active');

				if(this.validColors.length > 0 && !this.validColors.includes(color)) {
					result.push('inputdropdownbackgrondcolor__group__item--locked');
				}

				if(color === 'white') {
					result.push('inputdropdownbackgrondcolor__group__item--white');
				}

				return result.join(' ');
			},

			selectItem (color) {
				if(this.selected === color) {
					this.removeColor(color);
				} else if(
					typeof color !== 'undefined' &&
					this.validColors.length > 0 ? this.validColors.includes(color) : true
				) {
					this.update(color);
				}
			},

			update(value) {
				this.save(value);
				this.$emit('update', value);
			},

			removeColor () {
				this.update(this.validColors.includes(this.defaultValue) ? this.defaultValue : '');
			},

		}
	};
</script>

<style scoped lang="scss">

	$groupHeight: rem-calc(70);

	.inputdropdownbackgrondcolor {

		display: contents;

		// fixes for old edge
		padding: 0;
		border-bottom: none;

		&__selected {
			position: absolute;
			right: 0;
			top: 0;
			display: block;
			border-radius: 50%;
			border: 1px dashed $silver;

			box-sizing: border-box;
			width: $dropdown-compact-height;
			height: $dropdown-compact-height;

			&--fill {
				border: 2px solid var(--contrast, $white);
			}
		}

		&__item {

			height: $groupHeight !important;
			cursor: auto !important;

			@include hover {
				background-color: inherit !important;
			}

			label {
				margin-bottom: $half-gutter/2 !important;
			}

		}

		&__group {

			$padding: $half-gutter/2;

			display: flex;
			margin: 0 -#{$padding};

			&__item {
				display: block;
				flex: 0 1 rem-calc(35);

				border-radius: 50%;
				margin: 0 $padding;

				cursor: pointer;
				width: rem-calc(35);

				span {
					display: block;
					content: '';
				}

				&:before {
					@include icon-setting;
					display: block!important;
					line-height: 0!important;
					height: 0;
					padding: 50% 0;
					color: $white;
					content: '';
					text-align: center;
				}

				@include hover-or-active {
					@include icon-content('plus', ':before') {
						transform: translate(.5px, -.5px);
					}
				}

				&--active {
					@include icon-content('check', ':before');
					@include hover-or-active {
						@include icon-content('minus', ':before');
					}
				}

				&--locked {
					pointer-events: none;
					touch-action: none;
					@include icon-content('lock', ':before') {
						transform: translate(0, -1px);
					}
				}

				&--white {
					box-sizing: border-box;
					border: 1px solid $silver;
					color: $silver;

					&:before { color: inherit; }
				}

			}
		}

		&__footer {
			label {
				@include icon('delete', ':after', 'small') {
					margin-left: $half-gutter/2;
				}
			}
		}

		&__header {
			pointer-events: none;
			height: auto;
			label {
				display: block!important;
				margin-top: $half-gutter;
				color: $black;
			}
		}

		@include __ {

			.dropdown {
				$dropdown: &;

				&__item {
					&--selected {
						padding-right: $dropdown-compact-height;
						border-radius: $dropdown-compact-height;
					}

				}
			}

		}

	}

</style>

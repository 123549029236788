<template>
	<a @click="showExportManager([true, 'cart'])" class="cartbutton" :class="{'cartbutton--active': cartLength}">
		<span class="cartbutton__counter" :class="{'cartbutton__counter--active': cartLength}">{{ cartLength }}</span>
	</a>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    name: "ExportManagerCartButton",
	methods: {
		...mapActions('generator', ['showExportManager'])
	},
	computed: {
		...mapState('generator', ['cart']),
		cartLength: function(){
			return this.cart.length;
		}
	}
};
</script>

<style scoped lang="scss">

	.cartbutton {

		width: $gutter-normal;
		height: $gutter-normal;
		position: relative;
		transform: translateY(-1rem);

		@include is-round;
		@include icon('download', ':before', 'big');

		color: $primary;
		background: rgba($white,.2);
		transition: background-color $speed-slow, color $speed-slow, transform .1s ease-in;
		pointer-events: none;
		touch-action: none;

		&__counter {
			$size: rem-calc(24);
			width: $size;
			height: $size;
			opacity: 0;

			@include is-round;
			font-size: $font-size-small;
			right: rem-calc(-10);
			top: 0;

			background: $white;
			color: $tertiary;
			position: absolute;

			&--active {
				opacity: 1;
			}
		}

		&--active {
			cursor: pointer;
			pointer-events: all;
			touch-action: auto;

			color: $white;
			background: $tertiary;

			@include hover-or-active {
				transform: translateY(-.75rem);
			}
		}
	}
</style>




<template>
	<ul v-if="pager.pages && pager.pages.length" class="pagination">
		<li v-if="labels.first" class="pagination__item pagination__item--first" :class="{'disabled': pager.currentPage === 1}">
			<a class="pagination__item__link" @click="setPage(1)">{{labels.first}}</a>
		</li>
		<li v-if="labels.previous" class="pagination__item pagination__item--previous" :class="{'disabled': pager.currentPage === 1}">
			<a class="pagination__item__link" @click="setPage(pager.currentPage - 1)">{{labels.previous}}</a>
		</li>
		<li v-for="page in pager.pages" :key="page" class="pagination__item pagination__item--number" :class="{'active': pager.currentPage === page}">
			<a class="pagination__item__link" @click="setPage(page)">{{page}}</a>
		</li>
		<li v-if="labels.next" class="pagination__item pagination__item--next" :class="{'disabled': pager.currentPage === pager.totalPages}">
			<a class="pagination__item__link" @click="setPage(pager.currentPage + 1)">{{labels.next}}</a>
		</li>
		<li v-if="labels.last" class="pagination__item pagination__item--last" :class="{'disabled': pager.currentPage === pager.totalPages}">
			<a class="pagination__item__link" @click="setPage(pager.totalPages)">{{labels.last}}</a>
		</li>
	</ul>
</template>

<script>
	import {mapGetters} from 'vuex';
	import jwPaginate from 'jw-paginate';

	const defaultLabels = {
		first: null,
		last: null,
		previous: 'Previous',
		next: 'Next'
	};

	export default {
		name: 'Pagination',

		computed: {
			...mapGetters('generator', ['getL10nFromConfig', 'getGeneratorConfig']),
		},

		props: {
			items: {
				type: Array,
				required: true
			},
			initialPage: {
				type: Number,
				default: 1
			},
			pageSize: {
				type: Number,
				default: 10
			},
			maxPages: {
				type: Number,
				default: 10
			},
			labels: {
				type: Object,
				default: () => defaultLabels
			}
		},

		data () {
			return {
				pager: {}
			}
		},

		created () {
			if (!this.$listeners.changePage) {
				throw 'Missing required event listener: "changePage"';
			}

			this.labels.previous = this.getL10nFromConfig('prev');
			this.labels.next = this.getL10nFromConfig('next');

			// set to initial page
			this.setPage(this.initialPage);
		},

		methods: {
			setPage(page) {
				const { items, pageSize, maxPages } = this;

				// get new pager object for specified page
				const pager = jwPaginate(items.length, page, pageSize, maxPages);

				// get new page of items from items array
				const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

				// update pager
				this.pager = pager;

				// emit change page event to parent component
				this.$emit('changePage', pageOfItems, this.pager);
			}
		},

		watch: {
			items () {
				this.setPage(this.initialPage);
			}
		}
	}
</script>

<style scoped lang="scss">

	.pagination {

		$root: &;
		$hairline: 1px solid $light;

		@mixin active { @at-root #{&}.active { @content }}

		display: flex;
		flex-wrap: nowrap;
		align-items: center;

		margin: $gutter 0;

		cursor: default;

		&__item {

			$item: &;
			@include is-noselect;

			padding: 0 $half-gutter/1.5;

			&--first,
			&--previous,
			&--next,
			&--last {
				font-size: $font-size-small;
				font-weight: $font-weight-bold;
				text-transform: uppercase;

				color: $tertiary;
			}

			&--first {}
			&--previous {
				margin-right: auto;
				border-right: $hairline;
			}

			&--number {
				flex: 0;
				text-align: center;
				font-size: $font-size-normal;

				@include active {
					#{$item}__link {
						border-bottom-color: $tertiary;
					}
				}

			}

			&--next {
				border-left: $hairline;
				margin-left: auto;
			}

			&--last {}

			&.disabled {
				color: $silver;
				cursor: default;
			}


			&__link {
				display: block;
				padding: $half-gutter/2 $half-gutter/3;
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;

				@at-root :not(.disabled) > & {
					cursor: pointer;
					@include hover-or-active { color: $tertiary }
				}
			}
		}
	}

</style>
